import { Routes } from "Utils";
import { mdiAccountGroup, mdiHome, mdiInformation, mdiLoginVariant, mdiNewBox, mdiTrendingUp } from "@mdi/js";
import Icon from "@mdi/react";

import Home from "./Home";
import Page404 from "./Page404";
import WhatsNew from "./WhatsNew";

const routes = new Routes.Routes();

routes.route("/", {
    Component: Home,
    title: "Início",
    inMenu: true,
    icon: <Icon path={mdiHome} />,
    sectionId: "home",
    errorElement: <Page404 />,
});

routes.route("/about", {
    Component: Home,
    title: "Sobre",
    inMenu: true,
    icon: <Icon path={mdiInformation} />,
    sectionId: "about",
    color: "#E91E63",
});

routes.route("/main_advantages", {
    Component: Home,
    title: "Principais vantagens",
    inMenu: true,
    icon: <Icon path={mdiTrendingUp} />,
    sectionId: "main_advantages",
    color: "#009688",
});

routes.route("/ivip_community", {
    Component: Home,
    title: "Comunidade IVIP",
    inMenu: true,
    icon: <Icon path={mdiAccountGroup} />,
    sectionId: "ivip_community",
    color: "#607d8b",
});

routes.route("/ivip_community", {
    Component: Home,
    title: "Comunidade IVIP",
    inMenu: true,
    icon: <Icon path={mdiAccountGroup} />,
    sectionId: "ivip_community",
    color: "#607d8b",
});

routes.route("/login", {
    Component: WhatsNew,
    title: "Login",
    icon: <Icon path={mdiLoginVariant} />,
    color: "#00bcd4",
});

routes.route("/whats_new", {
    Component: WhatsNew,
    title: "O que há de novo",
    icon: <Icon path={mdiNewBox} />,
    color: "#009688",
});

export default routes;
