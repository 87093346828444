import { Link as RouterLink } from "@mui/material";
import { useRef } from "react";
import { navigate } from "Pages";

type RouterLinkProps = React.ComponentProps<typeof RouterLink>;

interface Props extends RouterLinkProps {
    href?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

export const Link: React.FC<Props> = ({ href, children, style, ...props }) => {
    const rootRef = useRef<HTMLAnchorElement>(null);

    const onClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
        if (!rootRef.current) {
            return;
        }

        const { pathname, host, href, target } = rootRef.current;

        if (target !== "_blank" && host === window.location.host) {
            e.preventDefault();

            const toHref = host === window.location.host ? pathname : href;

            navigate.to(toHref);
        }
    };

    return (
        <RouterLink
            ref={rootRef}
            href={href}
            {...props}
            onClick={onClick}
            style={{
                color: "currentcolor",
                ...style,
            }}
        >
            {children}
        </RouterLink>
    );
};
