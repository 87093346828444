import React, { useEffect, useRef } from "react";
import style from "./index.module.scss";
import { CondensedWithMenu, Logo } from "Components";

import Icon from "@mdi/react";
import { mdiGithub, mdiInstagram, mdiLinkedin, mdiWhatsapp } from "@mdi/js";
import { navigate } from "Pages";
import { Routes } from "Utils";

interface Props {
    menu?: Array<Routes.MenuItem>;
}

export const Header: React.FC<Props> = ({ menu }) => {
    const { itemSelected, condensed } = Routes.useRoutes(menu ?? []);

    const rootRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!rootRef.current) {
            return;
        }

        const buttons = rootRef.current.querySelectorAll("a");

        const onClick =
            (button: HTMLAnchorElement): EventListener =>
            (e) => {
                e.preventDefault();
                const { pathname, host, href } = button;

                const toHref = host === window.location.host ? pathname : href;

                navigate.to(toHref);
            };

        buttons.forEach((button) => {
            (button as any).__event = onClick(button);
            button.addEventListener("click", (button as any).__event);
        });

        return () => {
            buttons.forEach((button) => {
                button.removeEventListener("click", (button as any).__event);
            });
        };
    }, [rootRef.current]);

    return (
        <div className={style.header}>
            <div className={style.title}>
                <div className={style.logo}>
                    <Logo />
                </div>
                <div className={style.titleText}>
                    <span>iVip</span>
                    <span>Exchange</span>
                </div>
            </div>
            <div className={style.menu}>
                <div ref={rootRef}>
                    {menu?.map(({ title, icon, path, isActive, color }) => (
                        <a
                            href={path}
                            key={path}
                            rel="noreferrer"
                            style={
                                {
                                    "--primary": color,
                                } as React.CSSProperties & { [key: string]: any }
                            }
                            data-title={title}
                        >
                            <div className={[style.item, path === itemSelected?.path ? style.selected : ""].join(" ")}>{icon}</div>
                        </a>
                    ))}
                </div>
            </div>
            <div className={style.footer}>
                <div className={style.space}>
                    <div></div>
                </div>
                <div className={style.linkCondensed}>
                    <CondensedWithMenu items={condensed.map(({ pathname, title }) => ({ href: pathname, title }))} />
                </div>
                <div className={style.titleText}>
                    <span>iVipExchange</span>
                    &ensp;&ensp;©&ensp;&ensp;<span>{new Date().getFullYear()}</span>
                </div>
                <div className={style.socialMedia}>
                    <a href="#" rel="noreferrer">
                        <Icon path={mdiLinkedin} />
                    </a>
                    <a href="#" rel="noreferrer">
                        <Icon path={mdiInstagram} />
                    </a>
                    <a href="https://wa.me/551147574992" target="_blank" rel="noreferrer">
                        <Icon path={mdiWhatsapp} />
                    </a>
                    <a href="https://github.com/ivipex" target="_blank" rel="noreferrer">
                        <Icon path={mdiGithub} />
                    </a>
                </div>
            </div>
        </div>
    );
};
