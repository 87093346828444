import React from "react";

interface Props {
    color?: string;
    size?: number;
    style?: React.CSSProperties;
}

export const Logo: React.FC<Props> = ({ size, color, style }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 337.53 231.59"
            style={{
                fill: color,
                ...style,
            }}
        >
            <polygon points="78.64 134.72 78.64 171.29 105.11 171.29 78.64 207.98 72.35 216.69 24.53 216.69 24.53 14.91 76.17 14.91 78.64 18.53 107.2 60.31 78.64 60.31 78.64 89.32 127.04 89.32 145.14 115.8 131.49 134.72 78.64 134.72" />
            <polygon points="242.96 115.99 312.99 216.69 248.26 216.69 210.72 162.51 173.18 216.69 108.45 216.69 178.48 115.99 108.45 14.91 172.66 14.91 210.72 69.64 248.78 14.91 312.99 14.91 242.96 115.99" />
        </svg>
    );
};
