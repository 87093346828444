import React from "react";
import { SnackbarProvider, VariantType, enqueueSnackbar, closeSnackbar, SnackbarKey } from "notistack";

export const ToastProvider: React.FC<{
    children?: React.ReactNode;
    maxSnack?: number;
    autoHideDuration?: number;
    anchorOrigin?: Partial<{
        vertical: "top" | "bottom";
        horizontal: "left" | "center" | "right";
    }>;
}> = ({ children, maxSnack = 3, autoHideDuration = 5000, anchorOrigin = { vertical: "bottom", horizontal: "right" } }) => {
    return (
        <SnackbarProvider maxSnack={maxSnack} autoHideDuration={autoHideDuration} anchorOrigin={anchorOrigin as any}>
            {children}
        </SnackbarProvider>
    );
};

let listToasts: Array<SnackbarKey> = [];

export class Toast {
    static closeAll() {
        listToasts.splice(0).forEach((key) => {
            closeSnackbar(key);
        });
    }

    static clear() {
        this.closeAll();
    }

    static pop() {
        if (listToasts.length > 0) {
            closeSnackbar(listToasts.pop()!);
        }
    }

    static shift() {
        if (listToasts.length > 0) {
            closeSnackbar(listToasts.shift()!);
        }
    }

    static show(message: string, variant: VariantType = "default") {
        const id = enqueueSnackbar(message, {
            variant,
            onClose() {
                listToasts = listToasts.filter((key) => key !== id);
            },
        });

        listToasts.push(id);

        return {
            close() {
                closeSnackbar(id);
            },
        };
    }

    static success(message: string) {
        return this.show(message, "success");
    }

    static error(message: string) {
        return this.show(message, "error");
    }

    static warning(message: string) {
        return this.show(message, "warning");
    }

    static info(message: string) {
        return this.show(message, "info");
    }
}
