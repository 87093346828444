import React from "react";

import styles from "./index.module.scss";
import { Link } from "Components/Link";
import logoImage from "assets/image/logo.png";

const footerList: Array<{
    title: string;
    links: Array<{
        href: string;
        title: string;
        target?: "_blank";
    }>;
}>[] = [
    [
        {
            title: "Serviços IVIP",
            links: [
                { href: "https://ivipbank.com/", title: "iVipBank", target: "_blank" },
                { href: "https://ivipcoin.com/", title: "iVipCoin", target: "_blank" },
            ],
        },
    ],
    [
        {
            title: "Produtos",
            links: [
                { href: "#", title: "Spot" },
                { href: "#", title: "Inverse Perpetual" },
                { href: "#", title: "USDT Perpetual" },
                { href: "#", title: "Trocas" },
                { href: "#", title: "Futures" },
                { href: "#", title: "iViPay" },
                { href: "#", title: "iVip Card" },
            ],
        },
        {
            title: "Serviços",
            links: [
                { href: "#", title: "Comprar Crypto" },
                { href: "#", title: "Marcados" },
                { href: "#", title: "Tranding Fee" },
                { href: "#", title: "Affiliate Program" },
                { href: "#", title: "Referral Program" },
            ],
        },
    ],
    [
        {
            title: "SUPPORT",
            links: [
                { href: "#", title: "iVipEx Learn" },
                { href: "#", title: "Help Center" },
                { href: "#", title: "User Feedback" },
                { href: "#", title: "Submit a request" },
                { href: "#", title: "Trading Rules" },
            ],
        },
        {
            title: "API",
            links: [
                { href: "#", title: "Platform overview" },
                { href: "#", title: "Pricing" },
                { href: "#", title: "Documentation" },
                { href: "#", title: "API login" },
            ],
        },
    ],
    [
        {
            title: "SOBRE NÓS",
            links: [
                { href: "#", title: "Sobre iVipEx" },
                { href: "#", title: "Autenticação Check" },
                { href: "#", title: "Carreira" },
                { href: "#", title: "Contactos" },
                { href: "#", title: "Blog" },
            ],
        },
    ],
];

export const Footer: React.FC = () => {
    return (
        <div className={styles.main}>
            <div className={styles.info}>
                <div className={styles.institution}>
                    <div className={styles.logo} style={{ maskImage: `url(${logoImage})` }}></div>
                    <ul>
                        <li>
                            <Link href="https://wa.me/551147574992" target="_blank">
                                +55 11 4757-4992
                            </Link>
                        </li>
                        <li>
                            <Link href="contact@ivipex.com" target="_blank">
                                contact@ivipex.com
                            </Link>
                        </li>
                        <li>Rua Doutor - R. Luiz Alberto Vieira dos Santos, sala 803 - Vila Santista, Atibaia - SP, 12947-000</li>
                    </ul>
                </div>
                <div className={styles.items}>
                    {footerList.map((items, i) => {
                        return (
                            <div key={"items-" + i}>
                                {items.map((item, i) => {
                                    return (
                                        <div key={"item-" + i}>
                                            <span>{item.title}</span>
                                            <ul>
                                                {item.links.map((link, i) => (
                                                    <li key={"link-" + i}>
                                                        <Link href={link.href} target={link.target}>
                                                            {link.title}
                                                            {link.target === "_blank" && (
                                                                <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1 9L9 1M9 1H2.5M9 1V7.22222"></path>
                                                                </svg>
                                                            )}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={styles.copyright}>
                <p>© {new Date().getFullYear()} - All Rights Reserved by iVipExchange</p>
            </div>
        </div>
    );
};
