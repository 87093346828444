import React, { useState } from "react";
import styles from "./index.module.scss";

import SVGReceiveWhatsNew from "./SVGReceiveWhatsNew";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Toast } from "Helpers";
import { useDebouncedCallback } from "Utils/hooks";
import { Footer } from "Components";

const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const WhatsNew: React.FC = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const sendEmail = useDebouncedCallback(async () => {
        setLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 200));

        try {
            await fetch("https://api.ivipcoin.com:8080/resgister_email", {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({ email: email.toLocaleLowerCase() }),
            }).then((r) => {
                if (r.status !== 200) {
                    if (r.status === 500) {
                        Toast.warning("O e-mail informado já foi registrado");
                        return Promise.resolve();
                    }

                    return Promise.reject();
                }

                Toast.success("E-mail enviado com sucesso");
                return Promise.resolve();
            });

            setEmail("");
        } catch {
            Toast.error("Ocorreu um erro interno ao tentar registrar o e-mail");
        }

        setLoading(false);
    }, 200);

    const error = email.length > 0 && !emailRegex.test(email);
    const emailsDisapled = loading || email.length === 0 || !emailRegex.test(email);

    return (
        <>
            <div className={styles.header_card}>
                <p>What's New</p>
                <h1>
                    Fique por dentro do que há de <span>novo</span> na plataforma
                </h1>
                <p>As novidades chegam a você primeiro</p>
            </div>
            <div className={styles.mail_card}>
                <SVGReceiveWhatsNew
                    colors={{
                        primary: "var(--primary)",
                        secondary: "var(--secondary)",
                    }}
                />
                <div>
                    <h3>
                        Cadastre seu melhor e-mail e fique por dentro de todas as atualizações e novidades sobre o desenvolvimento da plataforma. Receba um convite para o pré-cadastro e tenha acesso
                        antecipado! Além disso, você receberá informações exclusivas e poderá acompanhar de perto as novas funcionalidades e melhorias!
                    </h3>
                    <TextField label="E-mail" variant="outlined" fullWidth type="email" autoComplete="off" value={email} onChange={(e) => setEmail(e.target.value)} error={error} disabled={loading} />
                    <LoadingButton variant="contained" disabled={emailsDisapled} loading={loading} onClick={sendEmail}>
                        Enviar
                    </LoadingButton>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default WhatsNew;
