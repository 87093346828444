import { useEffect, useState } from "react";
import { createBrowserRouter, matchRoutes, RouteObject, useLocation } from "react-router-dom";

interface RouteOptions extends Omit<RouteObject, "path"> {
    title?: string;
    icon?: React.ReactNode;
    inMenu?: boolean;
    sectionId?: string;
    color?: string;
}

function dispatchHistoryEvent(state: any) {
    window.dispatchEvent(new CustomEvent("popstate", { detail: { state } }));
}

// Salvar as funções originais
const originalPushState = window.history.pushState;
const originalReplaceState = window.history.replaceState;

// Sobrescrever pushState
window.history.pushState = function (state, title, url) {
    originalPushState.call(this, state, title, url);
    dispatchHistoryEvent(state);
};

// Sobrescrever replaceState
window.history.replaceState = function (state, title, url) {
    originalReplaceState.call(this, state, title, url);
    dispatchHistoryEvent(state);
};

export const useRoutes = (items: Array<MenuItem>) => {
    const [location, setLocation] = useState<{ hash: string; pathname: string; search: string }>({
        hash: window.location.hash,
        pathname: window.location.pathname,
        search: window.location.search,
    });

    useEffect(() => {
        const handlePopState = () => {
            setLocation({
                hash: window.location.hash,
                pathname: window.location.pathname,
                search: window.location.search,
            });
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    const condensed: Array<{
        pathname: string;
        item: MenuItem;
        title: string;
        icon?: React.ReactNode;
    }> = location.pathname
        .replace(/^\//, "")
        .replace(/\/$/, "")
        .split("/")
        .map((_, index, list) => {
            const pathname = "/" + list.slice(0, index + 1).join("/");
            const item = items.find((item) => item.validByPath(pathname));
            return {
                pathname,
                item,
                title: item?.title ?? pathname,
                icon: item?.icon,
            };
        })
        .filter((item) => item.item) as any;

    return { ...location, itemSelected: items.find((item) => item.isActive), condensed };
};

export class MenuItem {
    readonly title: string;
    readonly icon?: React.ReactNode;
    readonly path: string;
    readonly sectionId?: string;
    readonly color?: string;

    constructor(readonly route: Routes, path: string, title: string, icon?: React.ReactNode, sectionId?: string, color?: string) {
        this.title = title;
        this.icon = icon;
        this.path = path;
        this.sectionId = sectionId;
        this.color = color;
    }

    get isActive(): boolean {
        const routes = this.route.routesObject;
        const currentPath = window.location.pathname;
        const matchedRoutes = matchRoutes(routes as RouteObject[], currentPath);
        const activeRoutePath = matchedRoutes ? matchedRoutes[0].route.path : "";
        return this.path === activeRoutePath;
    }

    validByPath(pathname: string): boolean {
        const routes = this.route.routesObject;
        const matchedRoutes = matchRoutes(routes as RouteObject[], pathname);
        const activeRoutePath = matchedRoutes ? matchedRoutes[0].route.path : "";
        return this.path === activeRoutePath;
    }
}

export class Routes extends Map<string, RouteOptions> {
    private __browserRouter: ReturnType<typeof createBrowserRouter> | undefined;

    constructor() {
        super();
    }

    route(path: string, options: Omit<RouteOptions, "path">) {
        this.set(path, { ...options, path } as RouteOptions);

        if (this.__browserRouter) {
            this.__browserRouter.routes.push({ ...options, children: [], path } as any);
            this.__browserRouter.initialize();
        }
    }

    get router() {
        if (!this.__browserRouter) {
            this.__browserRouter = createBrowserRouter(
                (this.list as [string, RouteObject][]).map(([path, options]) => {
                    return { ...options, path };
                })
            );

            // window.addEventListener("popstate", () => {
            //     const currentPath = window.location.pathname;
            //     console.log("popstate", currentPath);
            //     this.__browserRouter?.navigate(currentPath);
            // });
        }
        return this.__browserRouter;
    }

    get routesObject(): RouteObject[] {
        return (this.list as [string, RouteObject][]).map(([path, options]) => {
            return { ...options, path };
        });
    }

    get list() {
        return Array.from(this.entries());
    }

    forMenu(): Array<MenuItem> {
        return this.list
            .filter(([, options]) => options.inMenu)
            .map(([path, options]) => {
                return new MenuItem(this, path, options?.title ?? path, options.icon, options.sectionId, options.color);
            });
    }

    to(
        to: string,
        opt?: Partial<{
            replace: boolean;
            state: any;
            body: any;
        }>
    ) {
        return this.router.navigate(to, { ...opt });
    }

    forward() {
        return this.router.navigate(-1);
    }

    back() {
        return this.router.navigate(1);
    }
}
