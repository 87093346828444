import React from "react";

interface Props {
    width?: string | number;
    height?: string | number;
    className?: string;
    style?: React.CSSProperties;
    colors?: Partial<{
        primary: string;
        secondary: string;
        tertiary: string;
        quaternary: string;
        white: string;
    }>;
}

const SVGReceiveWhatsNew: React.FC<Props> = ({ width, height, className, style, colors = {} }) => {
    const { primary = "#8380ff", secondary = "#a0d2f4", tertiary = "#ffe6de", quaternary = "#421b36", white = "#ffffff" } = colors;

    return (
        <svg className={className} viewBox="0 0 500 500" style={style} xmlSpace="preserve">
            <g>
                <g>
                    <g>
                        <path
                            style={{ fill: tertiary }}
                            d="M142.082,24.957c70.21-1.632,212.237-16.607,248.19,14.203c0,0,18.08,8.566,23.197,41.15
				c3.723,23.704,9.876,179.062,11.455,220.19c0.569,14.809-2.238,29.609-8.599,42.894c-8.389,17.522-24.579,37.169-55.782,45.671
				c-37.627,10.252-158.468,19.433-209.203-1.128c-50.735-20.561-56.592-57.686-56.592-57.686
				C79.537,266.383,82.247,175.883,79.087,110.43C79.087,110.43,74.057,26.539,142.082,24.957z"
                        />
                    </g>
                    <g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: white }}
                                    d="M108.112,79.799c0,0-16.077,45.585-11.474,48.464c4.602,2.879,64.163,19.086,65.692,16.289
						c1.529-2.797,12.927-41.373,11.658-45.699C172.72,94.527,110.454,78.173,108.112,79.799z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: tertiary }}
                                        d="M107.503,80.596c2.424,5.352,5.428,10.416,8.796,15.225c3.276,4.676,6.789,9.451,10.849,13.482
							c1.821,1.808,3.926,3.418,6.375,4.264c2.772,0.958,5.755,0.909,8.618,0.441c5.579-0.912,11.114-2.867,16.294-5.09
							c5.894-2.53,11.495-5.718,16.697-9.467c0.605-0.436-0.092-1.359-0.696-0.924c-8.84,6.371-18.919,11.151-29.521,13.728
							c-2.877,0.699-5.891,1.214-8.848,0.756c-2.841-0.44-5.27-1.907-7.368-3.82c-1.99-1.814-3.688-3.942-5.381-6.028
							c-1.73-2.132-3.41-4.303-5.019-6.528c-3.747-5.179-7.084-10.649-9.724-16.477C108.267,79.48,107.195,79.915,107.503,80.596
							L107.503,80.596z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: "#FFE6DE" }}
                                        d="M96.091,124.541c8.829-6.314,18.217-11.802,28.052-16.396c0.672-0.314,0.238-1.387-0.439-1.07
							c-9.922,4.634-19.402,10.172-28.309,16.542C94.788,124.051,95.486,124.975,96.091,124.541L96.091,124.541z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: "#FFE6DE" }}
                                        d="M145.199,113.706c6.221,9.552,11.488,19.694,15.734,30.273c0.277,0.691,1.348,0.254,1.07-0.439
							c-4.282-10.669-9.606-20.896-15.881-30.53C145.715,112.385,144.794,113.084,145.199,113.706L145.199,113.706z"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: white }}
                                    d="M344.191,150.97c0,0,1.927,38.259,6.168,38.985c4.24,0.726,52.785-5.309,53.064-7.82
						c0.28-2.511-2.969-34.223-5.201-37.013C395.991,142.331,345.419,149.073,344.191,150.97z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: "#FFE6DE" }}
                                        d="M343.985,151.738c3.387,3.194,7.113,6.004,11.03,8.516c3.809,2.443,7.82,4.887,12.01,6.621
							c1.879,0.778,3.906,1.325,5.957,1.208c2.32-0.132,4.493-1.065,6.451-2.269c3.816-2.348,7.286-5.446,10.415-8.634
							c3.561-3.628,6.708-7.65,9.394-11.965c0.313-0.502-0.476-0.969-0.788-0.468c-4.565,7.331-10.517,13.868-17.515,18.946
							c-1.899,1.378-3.954,2.662-6.26,3.216c-2.216,0.532-4.437,0.187-6.552-0.584c-2.005-0.731-3.89-1.781-5.759-2.801
							c-1.909-1.042-3.795-2.129-5.644-3.276c-4.305-2.67-8.398-5.676-12.086-9.155C344.209,150.69,343.554,151.331,343.985,151.738
							L343.985,151.738z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: "#FFE6DE" }}
                                        d="M348.839,187.391c4.574-7.286,9.806-14.134,15.635-20.461c0.398-0.432-0.243-1.088-0.644-0.653
							c-5.88,6.382-11.165,13.295-15.779,20.645C347.736,187.424,348.525,187.891,348.839,187.391L348.839,187.391z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: "#FFE6DE" }}
                                        d="M381.583,164.673c7.435,5.132,14.348,10.983,20.643,17.462c0.411,0.423,1.065-0.22,0.653-0.644
							c-6.35-6.534-13.33-12.431-20.828-17.606C381.565,163.549,381.099,164.339,381.583,164.673L381.583,164.673z"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: white }}
                                    d="M352.08,249.135c0,0,5.223,25.871,8.186,25.932c2.963,0.061,35.423-9.012,35.356-10.751
						c-0.066-1.739-5.52-23.014-7.326-24.687C386.491,237.955,352.723,247.717,352.08,249.135z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: "#FFE6DE" }}
                                        d="M352.018,249.679c2.634,1.83,5.46,3.364,8.385,4.675c2.845,1.275,5.828,2.53,8.86,3.284
							c1.36,0.338,2.797,0.504,4.182,0.215c1.568-0.327,2.952-1.185,4.164-2.205c2.36-1.99,4.408-4.455,6.214-6.947
							c2.055-2.836,3.789-5.898,5.178-9.112c0.162-0.374-0.424-0.612-0.585-0.238c-2.361,5.461-5.748,10.523-9.997,14.699
							c-1.153,1.133-2.422,2.218-3.937,2.831c-1.455,0.589-3.004,0.581-4.524,0.271c-1.441-0.293-2.832-0.816-4.21-1.32
							c-1.408-0.515-2.803-1.063-4.18-1.655c-3.206-1.379-6.302-3.009-9.171-5.003C352.064,248.942,351.683,249.446,352.018,249.679
							L352.018,249.679z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: "#FFE6DE" }}
                                        d="M358.968,273.475c2.372-5.431,5.237-10.632,8.562-15.539c0.227-0.335-0.277-0.717-0.506-0.379
							c-3.354,4.95-6.249,10.199-8.641,15.679C358.22,273.61,358.806,273.848,358.968,273.475L358.968,273.475z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: "#FFE6DE" }}
                                        d="M378.957,254.65c5.59,2.737,10.898,6.017,15.85,9.788c0.323,0.246,0.703-0.258,0.379-0.505
							c-4.994-3.803-10.353-7.108-15.99-9.868C378.83,253.886,378.594,254.472,378.957,254.65L378.957,254.65z"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: white }}
                                    d="M344.97,45.138c0,0-9.422,24.653-6.951,26.29c2.471,1.637,34.751,11.329,35.625,9.824
						c0.874-1.505,7.643-22.399,7.012-24.778C380.026,54.094,346.272,44.284,344.97,45.138z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: "#FFE6DE" }}
                                        d="M344.627,45.564c1.247,2.955,2.815,5.763,4.585,8.435c1.722,2.599,3.571,5.255,5.73,7.513
							c0.968,1.013,2.094,1.921,3.419,2.418c1.5,0.562,3.128,0.578,4.698,0.363c3.058-0.419,6.107-1.407,8.966-2.547
							c3.253-1.297,6.355-2.958,9.248-4.931c0.337-0.229-0.031-0.743-0.367-0.514c-4.916,3.352-10.485,5.818-16.309,7.074
							c-1.581,0.341-3.233,0.579-4.84,0.287c-1.545-0.281-2.849-1.116-3.967-2.19c-1.06-1.018-1.957-2.204-2.852-3.367
							c-0.914-1.188-1.8-2.397-2.647-3.634c-1.972-2.88-3.716-5.913-5.074-9.132C345.059,44.966,344.468,45.188,344.627,45.564
							L344.627,45.564z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: "#FFE6DE" }}
                                        d="M337.774,69.389c4.909-3.321,10.111-6.183,15.544-8.551c0.371-0.162,0.15-0.753-0.225-0.59
							c-5.481,2.389-10.734,5.277-15.687,8.627C337.07,69.103,337.437,69.617,337.774,69.389L337.774,69.389z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: "#FFE6DE" }}
                                        d="M364.732,64.172c3.26,5.302,5.991,10.912,8.159,16.747c0.142,0.381,0.732,0.158,0.591-0.224
							c-2.186-5.885-4.947-11.542-8.235-16.89C365.033,63.458,364.52,63.827,364.732,64.172L364.732,64.172z"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: white }}
                                    d="M108.533,259.565c0,0,1.927,38.259,6.168,38.985c4.24,0.726,52.785-5.309,53.064-7.82
						c0.279-2.511-2.969-34.223-5.201-37.013C160.333,250.926,109.761,257.668,108.533,259.565z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: "#FFE6DE" }}
                                        d="M108.327,260.332c3.387,3.194,7.113,6.004,11.03,8.516c3.809,2.443,7.821,4.887,12.01,6.621
							c1.879,0.778,3.907,1.325,5.956,1.208c2.32-0.132,4.493-1.065,6.451-2.269c3.816-2.348,7.286-5.446,10.415-8.634
							c3.561-3.628,6.708-7.65,9.394-11.965c0.313-0.502-0.476-0.969-0.788-0.468c-4.565,7.33-10.517,13.868-17.515,18.946
							c-1.899,1.378-3.954,2.662-6.26,3.216c-2.216,0.532-4.437,0.187-6.551-0.584c-2.005-0.731-3.89-1.781-5.759-2.801
							c-1.91-1.042-3.795-2.129-5.644-3.276c-4.305-2.67-8.398-5.676-12.086-9.155C108.551,259.285,107.896,259.926,108.327,260.332
							L108.327,260.332z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: "#FFE6DE" }}
                                        d="M113.18,295.986c4.574-7.286,9.806-14.134,15.635-20.461c0.398-0.432-0.243-1.088-0.644-0.653
							c-5.88,6.382-11.165,13.295-15.779,20.645C112.078,296.019,112.867,296.486,113.18,295.986L113.18,295.986z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: "#FFE6DE" }}
                                        d="M145.925,273.268c7.435,5.132,14.348,10.983,20.643,17.462c0.411,0.423,1.065-0.22,0.653-0.644
							c-6.35-6.534-13.33-12.431-20.828-17.606C145.907,272.144,145.441,272.934,145.925,273.268L145.925,273.268z"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M104.897,354.441c39.911,0,79.822,0,119.733,0c39.707,0,79.415,0,119.122,0
					c22.399,0,44.798,0,67.197,0c0.804,0,0.806-1.25,0-1.25c-39.911,0-79.822,0-119.733,0c-39.708,0-79.415,0-119.122,0
					c-22.399,0-44.798,0-67.197,0C104.093,353.191,104.092,354.441,104.897,354.441L104.897,354.441z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M96.316,335.374c42.196,0,84.392,0,126.588,0c41.981,0,83.962,0,125.943,0
					c23.682,0,47.363,0,71.045,0c0.804,0,0.806-1.25,0-1.25c-42.196,0-84.392,0-126.588,0c-41.981,0-83.962,0-125.943,0
					c-23.682,0-47.363,0-71.045,0C95.512,334.124,95.51,335.374,96.316,335.374L96.316,335.374z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M127.883,374.745c34.312,0,68.624,0,102.936,0c34.137,0,68.274,0,102.411,0
					c19.257,0,38.514,0,57.77,0c0.804,0,0.806-1.25,0-1.25c-34.312,0-68.624,0-102.936,0c-34.137,0-68.274,0-102.411,0
					c-19.257,0-38.513,0-57.77,0C127.079,373.495,127.078,374.745,127.883,374.745L127.883,374.745z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M167.353,394.375c22.56,0,45.12,0,67.681,0c22.56,0,45.12,0,67.681,0c12.654,0,25.307,0,37.961,0
					c0.804,0,0.806-1.25,0-1.25c-22.56,0-45.12,0-67.681,0c-22.56,0-45.12,0-67.681,0c-12.654,0-25.307,0-37.961,0
					C166.548,393.125,166.547,394.375,167.353,394.375L167.353,394.375z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M135.282,335.781c0,6.012,0,12.024,0,18.035c0,0.804,1.25,0.806,1.25,0
					c0-6.012,0-12.024,0-18.035C136.532,334.977,135.282,334.975,135.282,335.781L135.282,335.781z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M199.554,335.781c0,6.012,0,12.024,0,18.035c0,0.804,1.25,0.806,1.25,0
					c0-6.012,0-12.024,0-18.035C200.804,334.977,199.554,334.975,199.554,335.781L199.554,335.781z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M199.554,374.917c0,6.012,0,12.024,0,18.036c0,0.804,1.25,0.806,1.25,0
					c0-6.012,0-12.024,0-18.036C200.804,374.113,199.554,374.111,199.554,374.917L199.554,374.917z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M266.253,374.917c0,6.012,0,12.024,0,18.036c0,0.804,1.25,0.806,1.25,0
					c0-6.012,0-12.024,0-18.036C267.503,374.113,266.253,374.111,266.253,374.917L266.253,374.917z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M332.951,374.917c0,6.012,0,12.024,0,18.036c0,0.804,1.25,0.806,1.25,0
					c0-6.012,0-12.024,0-18.036C334.201,374.113,332.951,374.111,332.951,374.917L332.951,374.917z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M263.826,335.781c0,6.012,0,12.024,0,18.035c0,0.804,1.25,0.806,1.25,0
					c0-6.012,0-12.024,0-18.035C265.076,334.977,263.826,334.975,263.826,335.781L263.826,335.781z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M328.098,335.781c0,6.012,0,12.024,0,18.035c0,0.804,1.25,0.806,1.25,0
					c0-6.012,0-12.024,0-18.035C329.348,334.977,328.098,334.975,328.098,335.781L328.098,335.781z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M392.371,335.781c0,6.012,0,12.024,0,18.035c0,0.804,1.25,0.806,1.25,0
					c0-6.012,0-12.024,0-18.035C393.621,334.977,392.371,334.975,392.371,335.781L392.371,335.781z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M160.942,355.286c0,6.012,0,12.024,0,18.036c0,0.804,1.25,0.806,1.25,0
					c0-6.012,0-12.024,0-18.036C162.192,354.482,160.942,354.481,160.942,355.286L160.942,355.286z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M225.214,355.286c0,6.012,0,12.024,0,18.036c0,0.804,1.25,0.806,1.25,0
					c0-6.012,0-12.024,0-18.036C226.464,354.482,225.214,354.481,225.214,355.286L225.214,355.286z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M289.486,355.286c0,6.012,0,12.024,0,18.036c0,0.804,1.25,0.806,1.25,0
					c0-6.012,0-12.024,0-18.036C290.736,354.482,289.486,354.481,289.486,355.286L289.486,355.286z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M353.758,355.286c0,6.012,0,12.024,0,18.036c0,0.804,1.25,0.806,1.25,0
					c0-6.012,0-12.024,0-18.036C355.008,354.482,353.758,354.481,353.758,355.286L353.758,355.286z"
                            />
                        </g>
                    </g>
                </g>
            </g>
            <g id="Bench">
                <g>
                    <g>
                        <path
                            style={{ fill: quaternary }}
                            d="M97.434,335.374c42.254,0,84.507,0,126.761,0c42.038,0,84.076,0,126.114,0
				c23.714,0,47.427,0,71.141,0c0.804,0,0.806-1.25,0-1.25c-42.254,0-84.507,0-126.761,0c-42.038,0-84.076,0-126.114,0
				c-23.714,0-47.427,0-71.141,0C96.63,334.124,96.629,335.374,97.434,335.374L97.434,335.374z"
                        />
                    </g>
                </g>
            </g>
            <g id="Device">
                <g>
                    <g>
                        <path
                            style={{ fill: quaternary }}
                            d="M144.158,60.008c-0.291,4.885,9.37,257.947,17.786,267.861
				c8.417,9.914,159.265,11.614,160.665-2.814c1.218-12.554-5.973-207.989-11.928-263.409c-0.889-8.274-2.726-21.032-17.787-21.885
				c-6.617-0.375-99.866-4.472-138.801,5.321C145.404,47.269,144.313,57.413,144.158,60.008z"
                        />
                    </g>
                    <g>
                        <path
                            style={{ fill: white }}
                            d="M151.943,65.654c-0.269,4.519,8.667,238.598,16.452,247.768
				c7.785,9.17,147.318,10.743,148.613-2.603c1.127-11.612-5.525-192.387-11.033-243.649c-0.822-7.653-2.521-19.454-16.453-20.243
				c-6.121-0.347-92.374-4.137-128.389,4.922C153.095,53.871,152.086,63.254,151.943,65.654z"
                        />
                    </g>
                    <g>
                        <path
                            style={{ fill: secondary }}
                            d="M273.626,278.616h-67.105c-2.79,0-5.051-2.261-5.051-5.051v-9.445
				c0-2.789,2.261-5.051,5.051-5.051h67.105c2.79,0,5.051,2.261,5.051,5.051v9.445C278.677,276.355,276.416,278.616,273.626,278.616
				z"
                        />
                    </g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: primary }}
                                        d="M228.742,91.456c-11.399-2.049-48.919,49.468-49.281,49.613l3.724-1.247
							c1.636-0.162,100.374-20.414,100.374-20.414S240.141,93.506,228.742,91.456z"
                                    />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            style={{ fill: primary }}
                                            d="M179.262,141.297c0,0,11.178,74.281,19.593,74.875c8.415,0.595,101.971-20.594,102.029-25.548
								c0.058-4.954-12.43-66.204-17.325-71.216C278.665,114.397,181.291,137.357,179.262,141.297z"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: white }}
                                        d="M194.104,151.889c0,0-14.473-33.668-10.848-36.953c3.624-3.285,85.597-24.964,87.56-19.945
							c1.963,5.019,7.747,35.316,7.747,35.316s-24.775,35.318-39.877,37.904C223.584,170.796,194.104,151.889,194.104,151.889z"
                                    />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            style={{ fill: white }}
                                            d="M235.578,168.805c-15.677,0-40.704-16.988-41.835-17.714l-0.173-0.111l-0.081-0.189
								c-2.45-5.696-14.47-34.281-10.683-37.715c4.14-3.753,77.984-22.827,87.215-20.678c0.768,0.181,1.233,0.503,1.422,0.986
								c1.963,5.02,7.543,34.195,7.78,35.434l0.054,0.279l-0.164,0.232c-1.018,1.452-24.858,36.523-40.059,39.125
								C238.045,168.626,236.693,168.805,235.578,168.805z"
                                        />
                                    </g>
                                    <g>
                                        <g>
                                            <path
                                                style={{ fill: quaternary }}
                                                d="M235.578,168.18c-6.296-0.037-12.535-2.304-18.246-4.771
									c-5.626-2.43-11.046-5.335-16.306-8.475c-1.723-1.029-3.43-2.086-5.117-3.173c-0.476-0.307-0.949-0.617-1.422-0.928
									c-0.444-0.292-0.398-0.213-0.54-0.548c-1.653-3.891-3.254-7.803-4.765-11.752c-2.008-5.251-3.941-10.569-5.345-16.019
									c-0.522-2.025-1.021-4.109-1.122-6.205c-0.045-0.934-0.001-2.381,0.605-2.849c1.56-1.202,3.916-1.748,5.738-2.372
									c3.703-1.267,7.456-2.387,11.215-3.473c10.213-2.952,20.517-5.601,30.86-8.053c9.538-2.261,19.138-4.447,28.836-5.913
									c2.83-0.428,5.753-0.892,8.625-0.786c0.406,0.015,0.782,0.036,1.135,0.109c1.066,0.222,1.237,0.865,1.511,1.763
									c0.57,1.864,1.017,3.767,1.463,5.663c2.167,9.227,3.985,18.542,5.775,27.849c0.033,0.172,0.056,0.349,0.1,0.519
									c0.063,0.244-0.057,0.41,0.076,0.13c-0.107,0.225-0.309,0.445-0.451,0.648c-0.701,1.011-1.411,2.015-2.129,3.014
									c-2.726,3.793-5.556,7.512-8.502,11.136c-3.718,4.575-7.613,9.025-11.84,13.139c-3.778,3.677-7.901,7.332-12.693,9.631
									C240.687,167.594,238.189,168.136,235.578,168.18c-0.803,0.014-0.806,1.264,0,1.25c5.387-0.09,9.902-2.48,14.106-5.674
									c4.757-3.613,8.979-7.908,12.958-12.348c6.023-6.72,11.557-13.903,16.699-21.316c0.185-0.267,0.493-0.6,0.549-0.913
									c0.058-0.327-0.126-0.846-0.19-1.18c-0.292-1.518-0.587-3.036-0.885-4.553c-1.056-5.377-2.138-10.749-3.286-16.107
									c-0.958-4.476-1.803-9.071-3.238-13.427c-0.316-0.958-0.729-1.665-1.746-2.016c-0.494-0.171-1.03-0.226-1.549-0.263
									c-1.188-0.085-2.392-0.002-3.577,0.097c-8.854,0.739-17.663,2.788-26.316,4.716c-11,2.45-21.942,5.183-32.807,8.173
									c-4.506,1.24-9.001,2.523-13.46,3.923c-2.889,0.907-5.812,1.814-8.589,3.033c-0.637,0.28-1.371,0.57-1.884,1.059
									c-0.927,0.885-0.953,2.408-0.903,3.595c0.209,4.916,2,9.877,3.564,14.491c1.789,5.281,3.809,10.484,5.93,15.64
									c0.629,1.53,1.168,3.196,1.954,4.653c0.424,0.786,1.509,1.272,2.251,1.751c4.276,2.762,8.673,5.346,13.182,7.71
									c5.956,3.123,12.182,5.983,18.705,7.698c2.783,0.732,5.648,1.241,8.532,1.258
									C236.382,169.435,236.383,168.185,235.578,168.18z"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: quaternary }}
                                        d="M301.18,189.967c-14.375-11.554-29.694-21.899-45.792-30.9
							c-0.703-0.393-1.334,0.686-0.631,1.079c16.003,8.947,31.249,19.218,45.539,30.704
							C300.917,191.351,301.807,190.472,301.18,189.967L301.18,189.967z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M178.729,142.812c8.022,5.349,16.306,10.3,24.826,14.813c4.328,2.293,8.716,4.474,13.157,6.54
						c4.174,1.942,8.437,3.883,12.981,4.771c3.886,0.76,7.99,0.699,11.778-0.524c3.284-1.059,6.164-2.971,8.778-5.189
						c3.43-2.912,6.558-6.214,9.651-9.475c3.117-3.286,6.136-6.665,9.031-10.149c5.814-6.996,11.062-14.424,15.908-22.119
						c0.43-0.683-0.651-1.311-1.079-0.631c-8.923,14.17-19.4,27.227-31.484,38.839c-2.6,2.498-5.36,4.908-8.622,6.504
						c-3.437,1.681-7.321,2.241-11.115,1.901c-4.737-0.424-9.23-2.281-13.516-4.234c-4.239-1.932-8.427-3.98-12.564-6.123
						c-9.32-4.83-18.365-10.181-27.099-16.004C178.688,141.285,178.062,142.367,178.729,142.812L178.729,142.812z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M198.469,216.035c5.287-17.677,12.048-35.03,20.157-51.605c0.352-0.72-0.725-1.354-1.079-0.631
						c-8.156,16.67-14.965,34.124-20.283,51.903C197.033,216.475,198.239,216.805,198.469,216.035L198.469,216.035z"
                                />
                            </g>
                        </g>
                        <g>
                            <path
                                style={{ fill: primary }}
                                d="M207.804,138.879c-0.218,0.056-0.445,0.071-0.683,0.049c-0.238-0.022-0.439-0.099-0.605-0.231
					c-0.166-0.13-0.284-0.336-0.355-0.617l-3.783-16.295c-0.096-0.438-0.043-0.801,0.158-1.083c0.201-0.282,0.465-0.466,0.791-0.549
					c0.311-0.079,0.591-0.084,0.841-0.016c0.249,0.069,0.499,0.238,0.75,0.505c0.793,0.775,1.681,1.562,2.664,2.363
					c0.982,0.801,1.997,1.614,3.044,2.44c1.046,0.825,2.09,1.657,3.131,2.492c1.041,0.836,1.999,1.672,2.875,2.507
					c-0.279-1.286-0.549-2.637-0.81-4.052c-0.261-1.414-0.513-2.805-0.752-4.167c-0.241-1.362-0.451-2.591-0.63-3.688
					c-0.061-0.366-0.002-0.671,0.176-0.916c0.178-0.243,0.466-0.425,0.868-0.543c0.213-0.07,0.438-0.083,0.674-0.036
					c0.235,0.048,0.441,0.14,0.617,0.278c0.175,0.136,0.276,0.319,0.3,0.544c0.256,1.524,0.523,3.058,0.801,4.601
					c0.277,1.542,0.553,3.041,0.824,4.495c0.271,1.454,0.528,2.806,0.771,4.05c0.243,1.246,0.468,2.34,0.675,3.28
					c0.068,0.265,0.052,0.496-0.046,0.695c-0.098,0.199-0.23,0.361-0.397,0.486c-0.167,0.126-0.336,0.21-0.507,0.253
					c-0.265,0.068-0.516,0.073-0.753,0.02c-0.237-0.056-0.481-0.217-0.732-0.485c-0.615-0.72-1.292-1.428-2.032-2.125
					c-0.741-0.697-1.52-1.379-2.336-2.049c-0.816-0.669-1.641-1.336-2.473-2.002c-0.832-0.666-1.661-1.318-2.487-1.961
					c-0.826-0.642-1.616-1.272-2.37-1.892l2.871,12.232c0.099,0.389,0.029,0.7-0.209,0.935
					C208.436,138.63,208.146,138.792,207.804,138.879z"
                            />
                            <path
                                style={{ fill: primary }}
                                d="M226.464,134.046c-0.171,0.044-0.377,0.079-0.618,0.107c-0.241,0.028-0.463-0.014-0.665-0.128
					c-0.203-0.113-0.339-0.336-0.406-0.667c-0.316-1.757-0.639-3.542-0.968-5.353c-0.33-1.812-0.672-3.624-1.026-5.438
					c-0.355-1.813-0.714-3.593-1.076-5.338c-0.072-0.412-0.05-0.746,0.068-1c0.117-0.252,0.392-0.442,0.823-0.569
					c0.762-0.26,1.58-0.523,2.456-0.788c0.876-0.265,1.801-0.534,2.774-0.807c0.974-0.272,1.989-0.544,3.045-0.814
					c0.42-0.107,0.743-0.09,0.969,0.051c0.227,0.142,0.383,0.383,0.471,0.725c0.119,0.467,0.116,0.832-0.007,1.094
					c-0.123,0.263-0.387,0.446-0.79,0.549c-0.731,0.187-1.402,0.362-2.014,0.525c-0.612,0.166-1.188,0.329-1.725,0.491
					c-0.538,0.161-1.061,0.319-1.567,0.475c-0.508,0.153-1.035,0.325-1.583,0.514c0.116,0.583,0.235,1.166,0.359,1.748
					c0.123,0.582,0.24,1.155,0.352,1.723c0.111,0.567,0.217,1.145,0.318,1.731c0.66-0.267,1.349-0.518,2.068-0.751
					c0.718-0.233,1.417-0.443,2.098-0.635c0.681-0.189,1.265-0.331,1.755-0.422c0.264-0.067,0.522-0.029,0.773,0.114
					c0.252,0.143,0.425,0.4,0.52,0.773c0.126,0.433,0.104,0.781-0.068,1.048c-0.172,0.267-0.382,0.433-0.631,0.497
					c-0.396,0.068-0.92,0.189-1.571,0.363c-0.651,0.174-1.362,0.389-2.133,0.643c-0.77,0.255-1.547,0.54-2.327,0.855
					c0.184,0.979,0.366,1.956,0.548,2.926c0.181,0.973,0.352,1.935,0.513,2.888c0.536-0.169,1.112-0.359,1.73-0.566
					c0.618-0.207,1.256-0.415,1.915-0.624c0.659-0.209,1.298-0.41,1.92-0.602c0.622-0.192,1.197-0.355,1.725-0.49
					c0.327-0.083,0.601-0.046,0.824,0.113c0.222,0.158,0.37,0.411,0.441,0.756c0.107,0.42,0.104,0.765-0.009,1.033
					c-0.114,0.269-0.326,0.443-0.637,0.522c-0.311,0.08-0.626,0.16-0.944,0.241c-0.319,0.081-0.631,0.17-0.938,0.264
					c-0.727,0.202-1.476,0.426-2.249,0.672c-0.773,0.248-1.541,0.501-2.302,0.762C227.911,133.519,227.174,133.782,226.464,134.046z
					"
                            />
                            <path
                                style={{ fill: primary }}
                                d="M244.642,129.758c-0.296,0.075-0.574,0.084-0.837,0.027c-0.263-0.057-0.489-0.195-0.676-0.411
					c-0.188-0.217-0.322-0.515-0.401-0.891c-0.653-1.258-1.302-2.474-1.95-3.65c-0.647-1.176-1.299-2.334-1.954-3.475
					c-0.655-1.14-1.316-2.268-1.98-3.382c-0.665-1.113-1.341-2.237-2.026-3.37c-0.11-0.171-0.184-0.297-0.221-0.379
					c-0.038-0.081-0.065-0.16-0.085-0.239c-0.071-0.279-0.01-0.56,0.183-0.84c0.193-0.281,0.5-0.476,0.92-0.583
					c0.294-0.075,0.576-0.076,0.843-0.004c0.267,0.072,0.491,0.269,0.671,0.586c0.415,0.656,0.846,1.371,1.291,2.142
					c0.445,0.772,0.918,1.604,1.418,2.495c0.501,0.891,1.037,1.858,1.61,2.905c0.573,1.046,1.197,2.177,1.872,3.396
					c0.113-0.789,0.243-1.66,0.39-2.607c0.147-0.947,0.296-1.925,0.445-2.932c0.149-1.006,0.286-1.994,0.413-2.961
					c0.125-0.967,0.225-1.858,0.299-2.671c-0.087-0.342-0.149-0.716-0.186-1.119c-0.038-0.405-0.027-0.789,0.029-1.151
					c0.057-0.362,0.172-0.673,0.346-0.933c0.174-0.258,0.432-0.433,0.773-0.52c0.42-0.107,0.745-0.049,0.975,0.173
					c0.23,0.222,0.398,0.535,0.501,0.939c0.071,0.28,0.119,0.596,0.142,0.946c0.023,0.35,0.027,0.725,0.014,1.126
					c0.605,1.071,1.265,2.149,1.98,3.233c0.715,1.084,1.465,2.189,2.248,3.314c0.783,1.124,1.565,2.307,2.346,3.549
					c0.101-1.747,0.198-3.428,0.291-5.041c0.094-1.613,0.184-3.191,0.27-4.738c0.087-1.545,0.161-3.07,0.224-4.576
					c0.018-0.385,0.115-0.667,0.294-0.846c0.179-0.177,0.414-0.304,0.71-0.38c0.233-0.059,0.469-0.077,0.706-0.054
					c0.237,0.021,0.45,0.091,0.638,0.209c0.187,0.119,0.296,0.305,0.329,0.562c0.012,0.047,0.017,0.179,0.014,0.394
					c-0.003,0.216-0.01,0.467-0.02,0.75c-0.021,0.635-0.06,1.42-0.121,2.353c-0.06,0.934-0.129,1.961-0.207,3.082
					c-0.079,1.121-0.17,2.304-0.274,3.547c-0.105,1.244-0.215,2.505-0.327,3.783c-0.112,1.28-0.229,2.529-0.347,3.753
					c-0.041,0.424-0.146,0.761-0.313,1.011c-0.169,0.249-0.405,0.421-0.713,0.518c-0.342,0.087-0.654,0.063-0.937-0.072
					c-0.283-0.135-0.532-0.364-0.747-0.691c-0.741-1.085-1.352-1.985-1.832-2.699c-0.48-0.712-0.877-1.295-1.19-1.745
					c-0.313-0.451-0.566-0.824-0.758-1.124c-0.192-0.298-0.367-0.563-0.526-0.796c-0.159-0.232-0.335-0.486-0.529-0.759
					c-0.194-0.274-0.434-0.614-0.72-1.021c-0.286-0.408-0.672-0.947-1.158-1.617c-0.101,0.837-0.231,1.755-0.389,2.756
					c-0.159,1.001-0.323,2.011-0.494,3.031c-0.17,1.02-0.314,1.997-0.432,2.93c-0.119,0.932-0.194,1.758-0.226,2.478
					c0.075,0.099,0.153,0.227,0.235,0.387c0.083,0.162,0.14,0.305,0.172,0.429c0.075,0.361,0.015,0.675-0.183,0.94
					C245.277,129.489,245,129.666,244.642,129.758z"
                            />
                        </g>
                        <g>
                            <path
                                style={{ fill: primary }}
                                d="M267.972,119.002c-0.48,0.037-0.82-0.083-1.023-0.364c-0.202-0.278-0.305-0.637-0.308-1.074
					c0.008-0.091,0.004-0.339-0.012-0.746c-0.016-0.407-0.035-0.918-0.059-1.536c-0.025-0.617-0.053-1.287-0.084-2.008
					c-0.033-0.722-0.063-1.452-0.088-2.188c-0.025-0.738-0.056-1.43-0.09-2.076c-0.034-0.646-0.061-1.196-0.08-1.646
					c-0.011-0.346,0.086-0.615,0.29-0.803c0.204-0.189,0.486-0.297,0.847-0.325c0.345-0.026,0.634,0.039,0.864,0.195
					c0.229,0.155,0.352,0.408,0.362,0.753c0.029,0.979,0.055,1.814,0.077,2.506c0.023,0.693,0.048,1.321,0.075,1.885
					c0.028,0.563,0.056,1.13,0.085,1.703c0.028,0.571,0.061,1.217,0.102,1.938c0.039,0.722,0.084,1.602,0.133,2.639
					c0.01,0.332-0.083,0.599-0.278,0.802C268.589,118.862,268.318,118.976,267.972,119.002z M268.254,124.188
					c-0.437,0.019-0.806-0.131-1.109-0.448c-0.303-0.316-0.471-0.691-0.504-1.127c-0.032-0.421,0.076-0.784,0.324-1.089
					c0.249-0.305,0.621-0.478,1.116-0.515c0.434-0.048,0.794,0.08,1.081,0.382c0.287,0.302,0.447,0.679,0.481,1.128
					c0.033,0.436-0.077,0.814-0.332,1.135C269.055,123.976,268.704,124.154,268.254,124.188z"
                            />
                        </g>
                        <g>
                            <path
                                style={{ fill: primary }}
                                d="M192.476,125.22c0.392-0.194,0.79-0.208,1.195-0.042c0.407,0.166,0.712,0.441,0.92,0.825
					c0.2,0.372,0.248,0.747,0.146,1.127c-0.104,0.38-0.373,0.688-0.81,0.924c-0.378,0.22-0.759,0.25-1.144,0.091
					c-0.385-0.161-0.686-0.438-0.899-0.836c-0.207-0.384-0.259-0.775-0.157-1.172C191.83,125.74,192.079,125.434,192.476,125.22z
					 M194.84,129.843c0.424-0.229,0.785-0.256,1.083-0.084c0.297,0.173,0.538,0.459,0.718,0.856
					c0.029,0.087,0.134,0.313,0.313,0.677c0.179,0.365,0.406,0.824,0.678,1.378c0.273,0.554,0.571,1.154,0.894,1.801
					c0.324,0.646,0.646,1.3,0.97,1.964c0.323,0.663,0.632,1.284,0.926,1.86c0.294,0.576,0.542,1.068,0.742,1.472
					c0.15,0.312,0.172,0.595,0.062,0.852c-0.11,0.255-0.324,0.469-0.642,0.641c-0.305,0.164-0.594,0.222-0.868,0.173
					c-0.274-0.049-0.487-0.229-0.638-0.541c-0.424-0.883-0.788-1.635-1.089-2.258c-0.302-0.624-0.58-1.188-0.835-1.691
					c-0.255-0.504-0.51-1.01-0.769-1.521c-0.258-0.511-0.552-1.087-0.881-1.73c-0.329-0.644-0.727-1.43-1.194-2.358
					c-0.143-0.299-0.167-0.58-0.071-0.846C194.336,130.222,194.536,130.007,194.84,129.843z"
                            />
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M207.78,152.272c19.602-4.386,39.204-8.771,58.806-13.157c0.785-0.176,0.453-1.381-0.332-1.205
						c-19.602,4.386-39.204,8.771-58.806,13.157C206.663,151.242,206.995,152.447,207.78,152.272L207.78,152.272z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M209.571,157.803c17.991-4.159,35.982-8.318,53.973-12.476c0.784-0.181,0.452-1.387-0.332-1.205
						c-17.991,4.159-35.982,8.318-53.973,12.476C208.455,156.779,208.787,157.984,209.571,157.803L209.571,157.803z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M218.891,161.951c12.456-2.79,24.913-5.58,37.37-8.369c0.785-0.176,0.453-1.381-0.332-1.205
						c-12.457,2.79-24.913,5.579-37.37,8.369C217.774,160.921,218.106,162.126,218.891,161.951L218.891,161.951z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M204.053,92.988c-4.656-3.695-9.311-7.391-13.967-11.086c-0.624-0.495-1.514,0.384-0.884,0.884
						c4.656,3.695,9.311,7.391,13.967,11.086C203.793,94.367,204.683,93.488,204.053,92.988L204.053,92.988z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M221.86,85.507c-1.13-7.502-1.503-15.095-1.141-22.673c0.038-0.804-1.212-0.802-1.25,0
						c-0.367,7.693,0.038,15.39,1.185,23.005C220.774,86.632,221.979,86.295,221.86,85.507L221.86,85.507z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M241.339,86.54c3.363-5.352,7.082-10.468,11.142-15.313c0.513-0.613-0.366-1.501-0.884-0.884
						c-4.128,4.925-7.919,10.124-11.338,15.566C239.83,86.593,240.912,87.221,241.339,86.54L241.339,86.54z"
                                />
                            </g>
                        </g>
                    </g>
                    <g>
                        <path
                            style={{ fill: quaternary }}
                            d="M194.501,45.847c0,0-0.026,6.769,5.406,7.95c5.433,1.181,56.19,0.4,59.145-1.843
				c2.955-2.243,3.812-7.605,3.812-7.605l-67.125,1.498"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M186.442,233.471c28.97,0,57.939,0,86.909,0c8.158,0,16.316,0,24.474,0
					c0.804,0,0.806-1.25,0-1.25c-28.97,0-57.939,0-86.909,0c-8.158,0-16.316,0-24.474,0
					C185.638,232.221,185.636,233.471,186.442,233.471L186.442,233.471z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M185.51,246.401c29.435,0,58.869,0,88.303,0c8.315,0,16.63,0,24.944,0c0.804,0,0.806-1.25,0-1.25
					c-29.434,0-58.869,0-88.303,0c-8.315,0-16.63,0-24.945,0C184.706,245.151,184.704,246.401,185.51,246.401L185.51,246.401z"
                            />
                        </g>
                    </g>
                </g>
            </g>
            <g id="Line">
                <g>
                    <g>
                        <path
                            style={{ fill: primary }}
                            d="M339.961,106.761c1.171-1.72,2.341-3.441,3.512-5.161c0.54-0.794,1.081-1.588,1.621-2.382
				c0.478-0.702,1.607-2.967,2.468-1.513c0.462,0.781,0.544,2.126,0.749,2.997c0.227,0.966,0.454,1.932,0.681,2.899
				c0.492,2.093,0.983,4.187,1.475,6.281c0.073,0.31,0.46,0.55,0.769,0.437c5.891-2.159,11.996-3.672,18.219-4.477
				c-0.18-0.313-0.36-0.627-0.54-0.94c-2.932,5.224-5.864,10.447-8.797,15.671c-0.227,0.405,0.057,0.95,0.54,0.94
				c4.743-0.094,9.387,0.787,13.771,2.597c-0.05-0.381-0.1-0.761-0.149-1.142c-4.878,2.257-9.757,4.514-14.635,6.771
				c-0.729,0.337-0.095,1.415,0.631,1.079c4.878-2.257,9.757-4.514,14.635-6.771c0.548-0.254,0.288-0.962-0.149-1.142
				c-4.479-1.849-9.259-2.738-14.103-2.642c0.18,0.313,0.36,0.627,0.54,0.94c2.932-5.224,5.864-10.447,8.797-15.671
				c0.208-0.37-0.046-1.004-0.54-0.94c-6.34,0.821-12.548,2.322-18.551,4.522c0.256,0.146,0.513,0.291,0.769,0.437
				c-0.643-2.738-1.286-5.475-1.928-8.213c-0.301-1.28-0.51-2.637-0.935-3.881c-0.228-0.667-0.618-1.339-1.342-1.549
				c-1.837-0.535-3.062,2.11-3.856,3.277c-1.576,2.316-3.152,4.632-4.728,6.947C338.428,106.797,339.511,107.423,339.961,106.761
				L339.961,106.761z"
                        />
                    </g>
                </g>
            </g>
            <g id="Messages">
                <g>
                    <g>
                        <path
                            style={{ fill: primary }}
                            d="M127.212,178.801c0,0-10.159,31.466-6.959,33.351c3.2,1.884,44.226,11.876,45.22,9.936
				c0.994-1.941,8.082-28.529,7.135-31.464C171.662,187.688,128.784,177.646,127.212,178.801z"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M126.473,179.473c2.344,4.799,5.336,9.223,8.692,13.369c2.81,3.471,5.989,7.98,10.577,9.1
					c4.705,1.148,10.127-1.112,14.41-2.887c4.797-1.989,9.312-4.61,13.447-7.749c0.771-0.585-0.118-1.797-0.89-1.211
					c-3.765,2.857-7.832,5.273-12.168,7.162c-2.114,0.921-4.283,1.715-6.49,2.381c-2.411,0.727-5.047,1.419-7.576,0.929
					c-2.448-0.474-4.38-2.125-6.042-3.893c-1.659-1.765-3.214-3.646-4.717-5.545c-3.012-3.804-5.714-7.845-7.847-12.21
					C127.449,178.057,126.047,178.602,126.473,179.473L126.473,179.473z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M120.01,209.913c5.898-4.46,12.187-8.377,18.801-11.685c0.858-0.429,0.314-1.831-0.554-1.397
					c-6.728,3.365-13.137,7.334-19.137,11.871C118.348,209.286,119.237,210.497,120.01,209.913L120.01,209.913z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M152.897,201.506c4.412,6.396,8.186,13.201,11.273,20.332c0.383,0.885,1.783,0.337,1.397-0.554
					c-3.137-7.244-6.977-14.17-11.459-20.668C153.558,199.819,152.348,200.71,152.897,201.506L152.897,201.506z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            style={{ fill: primary }}
                            d="M288.535,74.465c0,0-3.459,21.356-1.203,22.259c2.256,0.902,29.779,3.309,30.23,1.955
				c0.451-1.354,2.406-19.251,1.504-21.056S289.437,73.562,288.535,74.465z"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M288.124,74.973c1.991,2.872,4.363,5.438,6.944,7.788c2.16,1.967,4.663,4.57,7.741,4.84
					c3.156,0.277,6.44-1.721,9.035-3.292c2.906-1.761,5.567-3.903,7.931-6.341c0.441-0.455-0.254-1.151-0.695-0.695
					c-2.153,2.221-4.544,4.185-7.162,5.835c-1.276,0.805-2.6,1.533-3.962,2.182c-1.488,0.709-3.124,1.417-4.808,1.35
					c-1.63-0.064-3.043-0.942-4.293-1.92c-1.248-0.978-2.439-2.041-3.599-3.12c-2.324-2.163-4.472-4.509-6.282-7.121
					C288.615,73.96,287.762,74.451,288.124,74.973L288.124,74.973z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M286.953,95.3c3.374-3.468,7.054-6.623,11.005-9.416c0.513-0.363,0.022-1.215-0.496-0.849
					c-4.019,2.842-7.772,6.042-11.204,9.57C285.816,95.058,286.511,95.754,286.953,95.3L286.953,95.3z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M307.393,86.611c3.486,3.701,6.599,7.729,9.302,12.035c0.335,0.535,1.187,0.042,0.849-0.496
					c-2.745-4.375-5.914-8.475-9.455-12.235C307.654,85.454,306.959,86.15,307.393,86.611L307.393,86.611z"
                            />
                        </g>
                    </g>
                </g>
            </g>
            <g id="Character">
                <g>
                    <g>
                        <path
                            style={{ fill: secondary }}
                            d="M276.716,223.751c0,0-7.605,20.06-4.936,28.724c2.668,8.665,18.341,10.056,23.05,7.481
				c4.709-2.575,31.058-81.197,31.058-81.197s-4.97,0.123-14.972,4.215c-10.001,4.092-33.448,40.351-33.448,40.351"
                        />
                    </g>
                    <g>
                        <path
                            style={{ fill: quaternary }}
                            d="M316.172,117.84c-4.599,1.14-8.913,3.41-12.458,6.554c-3.328,2.953-6.007,6.741-7.174,11.034
				c-1.167,4.294-0.715,9.104,1.676,12.857c1.964,3.083,5.167,5.453,6.204,8.958c1.198,4.047-0.861,8.298-3.134,11.854
				c-2.273,3.556-4.922,7.211-5.037,11.43c-0.085,3.1,1.239,6.132,1.001,9.224c-0.207,2.687-1.571,5.143-2.13,7.779
				c-1.405,6.625,2.494,13.343,7.546,17.854c0,0,11.596,1.137,17.368,1.246c0,0-3.382-9.198-0.477-14.061
				c2.905-4.863,17.514-21.852,17.514-21.852L316.172,117.84z"
                        />
                    </g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <path
                                                style={{ fill: "#FFBFBF" }}
                                                d="M127.126,412.094c1.288-0.076,2.654,0.246,4.048,0.021c6.35-1.022,13.465-4.035,18.394-7.865
									c6.01-4.67,13.546-32.378,13.338-33.311l22.221,21.643c0,0-15.129,19.845-18.493,27.157
									c-3.365,7.312-8.172,27.481-12.836,30.284c-4.664,2.803-37.795-10.639-49.571-16.762
									c-18.293-9.512-19.778-20.814-18.471-23.108C86.653,408.578,119.972,412.513,127.126,412.094z"
                                            />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path
                                                style={{ fill: secondary }}
                                                d="M86.416,409.837c0.814-0.104,1.56-0.11,2.914-0.099c0,0,0.031-0.143,0,0
									c0.227,0.462,7.307,14.141,27.223,25.372c20.255,11.418,42.059,9.693,42.059,9.693c-1.291,2.825-2.642,5.095-3.947,5.876
									c-0.149,0.089-0.325,0.158-0.523,0.215c-0.404,0.121-0.92,0.174-1.527,0.171c-8.782-0.042-37.369-11.747-48.179-17.368
									c-14.253-7.407-18.312-15.758-18.972-20.384C85.183,410.914,85.575,409.944,86.416,409.837z"
                                            />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            style={{ fill: primary }}
                                            d="M94.981,409.948l31.886,1.981c3.295,0.129,7.324-0.846,11.702-2.347c0,0-0.119-0.304,0,0
								c6.785-4.928,12.972-1.987,13.603,3.669c0.419,3.759-1.811,7.894-3.55,10.476c0.275,0.193,0.545,0.393,0.831,0.569
								c7.313,4.516,15.527,2.303,15.527,2.303c-2.145,6.615-4.465,13.443-6.397,17.726c0,0-22.782,1.817-42.776-9.451
								c-19.993-11.268-26.783-25.295-26.783-25.295L94.981,409.948z"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path
                                                style={{ fill: quaternary }}
                                                d="M138.649,424.995c-0.826-2.024-1.007-4.247-1.828-6.297c-0.908-2.268-2.22-4.214-3.734-6.12
									c-0.786-0.99,0.53-2.151,1.447-1.304c3.217,2.975,6.389,9.279,4.507,13.687
									C138.977,425.113,138.726,425.185,138.649,424.995L138.649,424.995z"
                                            />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path
                                                style={{ fill: quaternary }}
                                                d="M128.062,423.299c-1.295-3.468-1.587-7.209-4.422-9.907
									c-0.928-0.883,0.393-2.373,1.437-1.697c1.885,1.221,2.975,3.438,3.545,5.549c0.508,1.881,0.878,4.329-0.158,6.073
									C128.381,423.458,128.124,423.466,128.062,423.299L128.062,423.299z"
                                            />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path
                                                style={{ fill: quaternary }}
                                                d="M118.017,422.181c-0.674-1.555-0.499-3.363-0.907-5.003
									c-0.471-1.891-1.566-3.243-2.483-4.901c-0.714-1.292,1.008-2.189,1.969-1.357c2.553,2.208,4.507,8.298,2.471,11.311
									C118.825,422.589,118.207,422.619,118.017,422.181L118.017,422.181z"
                                            />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path
                                                style={{ fill: white }}
                                                d="M145.084,435.233c-4.063-0.255-7.898-1.449-11.577-3.159
									c-0.881-0.409-0.171-1.964,0.725-1.565c3.575,1.588,7.254,2.779,10.988,3.93
									C145.654,434.573,145.549,435.263,145.084,435.233L145.084,435.233z"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            style={{ fill: quaternary }}
                                            d="M157.611,445.035c-21.762,1.493-43.123-6.357-58.94-21.344
								c-4.138-3.921-8.301-8.291-10.236-13.76c-0.373-1.054,1.329-1.418,1.698-0.374c1.725,4.877,5.539,8.824,9.188,12.355
								c3.734,3.613,7.818,6.856,12.191,9.664c8.666,5.564,18.464,9.369,28.642,10.987c5.796,0.921,11.674,1.137,17.527,0.736
								C158.797,443.221,158.717,444.959,157.611,445.035L157.611,445.035z"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g>
                            <path
                                style={{ fill: primary }}
                                d="M299.003,253.74l-11.702,32.409l28.843,37.879l42.083-3.703c0,0,9.49-11.338,6.807-27.728
					c-2.725-16.641-11.632-37.155-11.632-37.155l-51.208-1.641L299.003,253.74z"
                            />
                        </g>
                        <g>
                            <path
                                style={{ fill: primary }}
                                d="M364.923,292.598c-2.409,0.263-80.739-9.186-118.331-10.56
					c-37.591-1.374-44.63,11.72-48.946,16.582c-4.82,5.429-52.572,88.998-51.26,92.931c1.311,3.933,45.03,19.88,46.976,19.443
					s38.464-76.33,38.464-76.33l94.816,1.414C350.052,336.427,368.234,315.775,364.923,292.598L364.923,292.598z"
                            />
                        </g>
                        <g style={{ opacity: 0.3 }}>
                            <path
                                style={{ fill: quaternary }}
                                d="M214.953,336.577c-0.369,0.228-0.761,0.161-1.153,0.077c0,0.32,0,0.64,0,0.961
					c-1.093-0.001-1.315-2.827,0-2.16c0-0.08,0-0.16,0-0.24C214.763,335.478,214.82,335.545,214.953,336.577z M224.644,325.278
					l0.158-1.46C224.459,324.134,224.382,324.693,224.644,325.278z M216.459,337.984c-0.906,0.259-0.143,1.448-0.091,2.078
					c0.705,0.774,1.487-0.284,1.984-0.805C218.26,338.093,217.53,337.734,216.459,337.984z M225.209,320.037l0.181-1.68
					c-0.373,0.102-0.774,0.281-1.197,0.529C224.185,319.349,224.601,319.764,225.209,320.037z M221.809,345.445
					c0.23,0.065,0.434,0.156,0.633,0.244l0.216-2.003C221.925,343.522,221.238,344.121,221.809,345.445z M221.533,339.373
					c0.408,0.671,1.019,0.835,1.523,0.631l0.202-1.872C222.392,337.716,221.366,338.118,221.533,339.373z M223.262,334.508
					c0.193-0.099,0.322-0.218,0.423-0.347l0.12-1.114c-0.397-0.326-0.8-0.656-1.188-0.975
					C221.146,333.309,221.245,334.261,223.262,334.508z M223.524,320.691c-1.011-0.46-1.95,0.108-1.769,1.336
					C222.645,323.514,224.616,322.379,223.524,320.691z M222.58,328.723c0.779-0.403,0.863-1.077,0.979-1.779
					c-0.181-0.226-0.375-0.468-0.527-0.658c-1.029,0.068-1.322,0.784-1.759,1.349C221.621,328.156,221.997,328.545,222.58,328.723z
					 M218.693,325.246c0.768,0.91,2.313,0.25,2.551-0.875C219.992,323.019,219.259,323.781,218.693,325.246z M216.019,332.621
					c-0.224,1.314-0.026,2.282,1.521,1.884c0.105-0.384,0.196-0.722,0.311-1.143C217.468,332.398,217.104,331.821,216.019,332.621z
					 M216.691,328.79c0.362-0.173,0.744-0.355,1.109-0.529c0.111-0.334,0.209-0.625,0.33-0.984c-0.654-0.432-0.965-0.578-1.436-0.57
					c-0.252,0.335-0.506,0.675-0.763,1.026C216.117,328.105,216.227,328.537,216.691,328.79z M219.836,340.845
					c-0.525,0.223-0.998,0.414-1.175,0.94c0.223,0.396,0.43,0.761,0.671,1.188C221.397,342.676,221.831,341.917,219.836,340.845z
					 M216.351,345.788c0.444,0,0.865,0,1.359,0c-0.045-0.64,0.694-1.08,0.293-1.777c-0.392-0.086-0.769-0.169-1.152-0.253
					C215.853,344.282,215.876,344.866,216.351,345.788z M220.628,336.796c-0.016-0.53-0.185-0.99-0.443-1.416
					c-1.354-0.145-1.354-0.145-1.724,0.999C218.977,336.897,220.097,337.603,220.628,336.796z M220.673,331.238
					c-0.128-0.522-0.15-0.934-0.006-1.401C218.687,328.607,218.121,332.849,220.673,331.238z M213.799,364.494
					c-1.522-0.555-1.466,2.022,0,1.92c1.104,0.435,1.731-0.859,1.351-1.828c-0.442-0.238-0.738-0.773-1.351-0.812
					C213.799,364.014,213.799,364.254,213.799,364.494z M214.867,358.621c-0.391,0.041-0.729,0.077-1.067,0.113
					c-0.179-0.041-0.358-0.082-0.5-0.113c-0.601,0.682-0.823,2.204,0.5,2.033c0.464,0.092,0.926,0.124,1.343-0.023
					C215.843,359.792,215.189,359.236,214.867,358.621z M213.799,352.494c0,0.08,0,0.16,0,0.24c-1.397,0.059-1.095,1.781,0,2.16
					c0-0.08,0-0.16,0-0.24c0.218,0.08,0.435,0.158,0.685,0.25C215.785,354.14,215.159,352.599,213.799,352.494z M213.799,369.774
					c0,0.24,0,0.48,0,0.72c-0.562-0.141-0.798,0.34-1.292,0.601c0.333,0.497,0.559,0.974,1.153,1.05
					c0.328-0.672,0.651-1.334,0.967-1.981C214.422,369.915,214.145,369.754,213.799,369.774z M220.759,347.934
					c-0.036-0.987-0.036-0.987-1.141-1.232c-0.556,0.292-1.51,0.499-1.02,1.232C218.737,349.247,220.772,349.256,220.759,347.934z
					 M219.246,360.643c0.438-0.908,0.815-1.69,1.123-2.327c-0.032-0.026-0.065-0.053-0.097-0.08
					C218.388,358.258,218.305,359.85,219.246,360.643z M217.937,361.877c-1.358-1.645-2.173-0.522-2.438,1.181
					C216.332,363.769,218.845,363.194,217.937,361.877z M216.78,358.005c2.175-0.458,1.227-3.697-0.751-2.168
					C215.975,356.695,215.762,357.561,216.78,358.005z M218.685,353.194c-0.317,0.948,0.522,1.794,1.457,1.77
					c0.434-0.088,0.59-0.207,0.506-0.715C221.544,352.616,219.852,352.585,218.685,353.194z M221.707,351.049
					c0.019,0.126,0.062,0.244,0.12,0.354l0.078-0.72C221.839,350.804,221.779,350.914,221.707,351.049z M218.058,350.229
					c-0.982-0.489-1.939-0.578-2.218,0.669C217.151,352.507,217.69,352.104,218.058,350.229z M212.402,340.933
					c0.312,0.871-0.044,2.391,1.397,1.961c2.038,0.008,1.869-1.971,0-2.16C213.349,340.525,212.219,340.005,212.402,340.933z
					 M207.08,347.934c-0.103,1.454,1.746,1.19,1.439,0c0.24,0,0.48,0,0.721,0c0.084-1.91-2.027-1.473-2.4,0
					C206.92,347.934,206.999,347.934,207.08,347.934z M213.393,331.333c0.121,0.034,0.256,0.048,0.406,0.041c0-0.08,0-0.16,0-0.24
					c1.467-0.042,1.724-1.094,0.861-1.63C214.243,330.097,213.821,330.706,213.393,331.333z M209.113,342.97
					c0.879-1.426,0.046-2.227-1.422-1.891c-1.033,0.138-0.754,1.194-0.321,1.832C207.774,343.456,208.538,342.963,209.113,342.97z
					 M206.544,344.697c-0.322-0.315-0.657-0.643-0.973-0.951c-0.013,0.003-0.023,0.005-0.036,0.008
					c-0.391,0.655-0.784,1.318-1.178,1.986C204.981,346.398,206.183,346.18,206.544,344.697z M211.204,343.478
					c-0.412,0.198-0.838,0.402-1.323,0.637c0.066,0.623,0.121,1.127,0.176,1.627c0.522,0.103,0.984,0.192,1.467,0.286
					C212.716,345.371,212.235,343.979,211.204,343.478z M212.314,333.214c-0.029-0.051-0.057-0.1-0.085-0.151
					c-0.297,0.448-0.597,0.905-0.898,1.367C211.656,334.43,211.847,334.197,212.314,333.214z M210.05,338.32
					c-0.031,0.445-0.116,0.911-0.238,1.351c0.346,0.284,0.674,0.554,1.001,0.823c0.465-0.481,0.934-0.798,1.536-1.014
					C212.076,338.263,211.551,338.005,210.05,338.32z M203.48,347.934c-0.08,0-0.16,0-0.24,0c0.144-0.19,0.173-0.365,0.142-0.532
					c-0.313,0.536-0.626,1.074-0.939,1.616C202.949,348.783,203.614,348.59,203.48,347.934z M213.799,349.134
					c0,0.079,0,0.161,0,0.24c0.873,0.197,1.022-0.841,1.2-1.44c0.08,0,0.16,0,0.24,0c-0.016-0.366,0.148-0.768-0.164-1.047
					c-0.458,0.031-0.867,0.06-1.276,0.087c-0.527,0.23-1.337,0.264-1.439,0.96C212.571,348.722,213.056,348.971,213.799,349.134z
					 M186.498,399.851c-0.755-1.851-2.626-1.822-2.871,0.285C184.715,401.568,185.626,401.031,186.498,399.851z M199.728,398.347
					c2.011-2.247-1.323-3.964-1.76-0.939c0.16,0.297,0.326,0.605,0.506,0.939C198.903,398.347,199.293,398.347,199.728,398.347z
					 M175.226,403.237c0.625,0.408,0.625,0.408,2.615,0.499C177.887,401.09,175.43,400.238,175.226,403.237z M195.334,365.416
					c-0.102,0.878,1.647,1.908,1.887,0.803c0.031-0.477,0.329-0.677,0.66-0.934C197.671,363.642,195.384,363.876,195.334,365.416z
					 M192.505,409.279c0.826,0.624,1.551,0.489,2.242-0.093c0.008-0.012,0.016-0.026,0.023-0.039
					C195.2,406.302,192.047,406.89,192.505,409.279z M189.679,392.597c-0.203,1.009-0.214,2.587,0.999,2.888
					c0.299-0.523,0.746-0.91,1.326-1.149C191.811,393.045,190.798,392.788,189.679,392.597z M188.886,409.508
					c-0.266-0.257-0.451-0.437-0.675-0.653c0.398-0.443,1.048-0.73,0.794-1.443c-0.44-0.197-0.861-0.127-1.359-0.203
					c-0.435,0.672-0.908,1.173-0.881,1.698c0.695,0.232,1.369,0.456,2,0.664C188.805,409.549,188.841,409.532,188.886,409.508z
					 M195.378,381.765c0.311,2.108,0.75,2.772,2.645,1.172C197.469,381.738,196.6,380.797,195.378,381.765z M177.531,397.255
					c0.086-0.429,0.01-0.755-0.154-0.991c-0.304,0.611-0.594,1.197-0.867,1.749C176.875,397.89,177.24,397.623,177.531,397.255z
					 M204.468,357.728c0.693,0.249,1.268,0.08,1.873-0.434c-0.309-0.615,0.179-0.97-0.14-1.553c-0.573,0-1.194,0-1.806,0
					c-0.227,0.32-0.429,0.607-0.643,0.91C204.005,357.031,204.242,357.388,204.468,357.728z M188.036,392.589
					c0.34-0.353,0.796-0.624,1.232-0.809c0.339-1.117-0.88-1.34-1.632-1.685c-0.504,0.469-0.816,1.031-0.867,1.744
					C187.115,392.212,187.572,392.365,188.036,392.589z M179.149,398.461c-1.34,0.891-1.405,2.475,0.509,2.486
					C180.861,400.332,180.611,398.535,179.149,398.461z M180.46,405.105c-0.417-0.318-0.811-0.619-1.193-0.912
					c-0.396,0.258-0.725,0.473-1.084,0.707c-0.095,0.314-0.195,0.647-0.302,0.998c0.542,0.185,1.115,0.381,1.709,0.584
					C180.101,406.329,180.476,405.879,180.46,405.105z M181.376,395.959c-0.041,0.59-0.041,0.59-0.537,1.119
					c0.265,1.524,2.446,1.052,2.693-0.131C183.072,395.986,182.255,395.925,181.376,395.959z M189.023,373.863
					c0.027-0.148,0.023-0.276,0.004-0.394c-0.214,0.407-0.428,0.814-0.64,1.218C188.621,374.478,188.843,374.194,189.023,373.863z
					 M190.927,372.156c0.067-0.783,1.338-0.555,0.978-1.486c-0.017-0.612-0.42-0.94-0.859-1.019
					c-0.367,0.689-0.73,1.375-1.091,2.058C190.138,372.237,190.549,372.658,190.927,372.156z M208.358,377.959
					c0.585-0.258,0.714-0.842,1.071-1.237c-0.223-0.345-0.412-0.636-0.608-0.938c-1.36-0.13-2.605,0.438-1.636,1.878
					C207.617,377.771,207.995,377.867,208.358,377.959z M200.341,357.539c1.115-1.405-1.691-3.021-1.879-0.906
					C198.192,357.822,199.625,358.586,200.341,357.539z M183.126,384.854c-0.271,0.531-0.538,1.055-0.801,1.572
					C183.186,386.347,183.679,385.613,183.126,384.854z M190.958,375.683c-0.351,0.287-0.799,0.453-1.264,0.487
					c-0.119,0.235-0.226,0.445-0.338,0.667C190.635,379.311,193.467,376.987,190.958,375.683z M203.446,376.007
					c-0.412-0.745-1.314-0.103-1.983-0.045c-0.1,0.266-0.209,0.555-0.343,0.912c0.431,0.605,0.768,1.175,1.538,0.736
					C203.83,377.302,203.943,377.074,203.446,376.007z M194.011,380.841c0.939-0.412,1.308-2.23-0.02-2.191
					c-0.413,0.094-0.65-0.221-1.041-0.257c-0.018,0.316-0.033,0.619-0.047,0.896c-0.246,0.139-0.443,0.252-0.648,0.369
					C192.584,380.464,193.249,380.683,194.011,380.841z M201.121,359.941c0.32,1.357,2.232,0.687,2.332-0.465
					C202.541,358.081,200.99,357.933,201.121,359.941z M204.478,369.226c1.79,0.242,3.285-1.828,0.55-2.25
					c-0.22,0.104-0.465,0.219-0.839,0.395C204.424,368.048,204.131,368.707,204.478,369.226z M201.433,394.718
					c0.352,0.086,0.611,0.202,0.894,0.262c0.287-0.565,0.578-1.139,0.871-1.719c-0.107-0.18-0.211-0.361-0.296-0.522
					C201.704,392.421,201.066,393.798,201.433,394.718z M189.064,403.469c0.097-0.849,0.063-1.614-1.055-1.94
					C185.669,402.063,187.584,404.88,189.064,403.469z M197.101,377.941c1.013-0.594,0.836-2.159-0.384-2.327
					C194.91,375.715,195.406,378.418,197.101,377.941z M205.706,361.263c-0.985-0.328-1.861,0.737-1.683,1.682
					C205.336,364.567,207.014,362.665,205.706,361.263z M200.886,353.814c0.283,0.43,0.629,0.794,1.072,1.088
					c0.426-0.165,0.828-0.322,1.273-0.494C203.656,352.231,201.66,352.081,200.886,353.814z M205.4,375.111
					c0.422-0.154,0.785-0.303,0.877-0.808c-0.432-1.93-1.267-1.823-2.549-0.608C203.945,374.664,204.788,374.742,205.4,375.111z
					 M199.578,363.751c0.598-0.151,0.717-0.715,1.056-1.079C199.673,359.733,196.644,361.965,199.578,363.751z M178.583,395.043
					c1.025,0.16,2.165-0.23,1.838-1.508c-0.457-0.199-0.912-0.396-1.383-0.601c-0.29,0.578-0.568,1.134-0.839,1.677
					C178.281,394.763,178.397,394.911,178.583,395.043z M181.878,407.26c0.157,0.053,0.315,0.107,0.472,0.16
					c-0.067-0.106-0.133-0.21-0.204-0.328C182.047,407.143,181.959,407.2,181.878,407.26z M193.902,384.055
					c-0.358,0.223-0.69,0.429-1.047,0.651c0.092,0.453,0.069,0.915-0.098,1.331c1.077,1.253,1.724,0.261,2.268-0.735
					C194.644,384.879,194.281,384.476,193.902,384.055z M199.896,378.75c-0.881-0.801-1.391,0.496-1.836,1.115
					c0.643,0.647,1.346,0.844,2.14,0.73C200.864,379.561,200.69,379.457,199.896,378.75z M187.03,379.636
					c-0.253,0.367,0.113,0.824,0.269,1.231c2.122,0.421,2.27-1.624,0.666-2.521c-0.232,0.141-0.566,0.246-0.736,0.474
					C187.08,379.02,187.378,379.4,187.03,379.636z M202.337,372.437c0.282-0.436,0.557-0.861,0.883-1.363
					c-0.413-2.241-2.137-1.276-2.117,0.574C201.515,371.94,201.685,372.543,202.337,372.437z M200.738,373.31
					c-1.23-0.732-3.773,0.554-1.727,1.545C200.128,374.992,201.053,374.713,200.738,373.31z M199.489,384.146
					c-0.689,0.701-1.879,1.328-0.773,2.156c0.481,0.556,0.953,0.263,1.464-0.058C201.128,385.648,200.083,384.608,199.489,384.146z
					 M197.105,358.47c-0.37,0.67-0.74,1.34-1.108,2.011C198.048,360.804,198.394,358.809,197.105,358.47z M181.863,392.356
					c0.353-0.089,0.732-0.184,1.133-0.284c0.107-0.295,0.211-0.587,0.306-0.85c-0.19-0.59-0.495-1.037-1.04-1.345
					c-0.408,0.309-0.811,0.612-1.188,0.896C181.171,391.419,181.141,392.058,181.863,392.356z M207.83,360.669
					c1.395,0.298,1.422-0.987,0.979-1.906C207.02,357.731,206.283,359.429,207.83,360.669z M205.458,352.044
					c0.899-1.139,0.899-1.139,0.477-2.078c-0.412,0-0.84,0-1.327,0C203.755,351.048,203.917,352.208,205.458,352.044z
					 M189.615,388.093c0.195,0.454,0.381,0.885,0.564,1.311c1.134,0.372,2.232-0.702,1.625-1.552
					C191.068,387.461,189.976,387.021,189.615,388.093z M195.469,387.761c-0.231,1.674,1.462,2.342,2.226,0.85
					C197.327,387.292,196.621,386.753,195.469,387.761z M183.329,402.638c-0.168-1.249-1.901-0.888-2.573-0.268
					C180.966,403.823,183.256,404.306,183.329,402.638z M198.031,368.192c1.203,1.401,2.641,1.761,2.29-0.587
					c-0.273-0.195-0.528-0.377-0.888-0.633C198.933,367.406,198.248,367.531,198.031,368.192z M195.496,406.085
					c0.198,0.256,0.415,0.472,0.674,0.638c0.327-0.588,0.678-1.231,1.048-1.916C195.938,404.855,195.597,404.428,195.496,406.085z
					 M207.613,354.984c2.857-0.999,0.955-3.821-1.001-1.184C206.958,354.209,207.281,354.592,207.613,354.984z M186.446,388.582
					c-1.004-2.272-2.685-1.34-2.032,0.83C185.38,389.817,185.834,389.056,186.446,388.582z M204.544,386.381
					c1.156,0.168,2.312-0.571,1.495-1.743c-0.661,0.156-1.949-0.411-2.044,0.485C203.963,385.606,203.908,386.307,204.544,386.381z
					 M202.767,389.398c0.605-0.526,0.963-0.686,0.561-1.963c-0.812-0.259-1.866-0.316-2.105,0.754
					C201.718,388.634,202.044,389.815,202.767,389.398z M209.847,379.918c0.212-0.429,0.422-0.857,0.632-1.284
					C210.02,378.973,209.598,379.518,209.847,379.918z M192.462,368.097c0.46,1.368,1.296,1.612,2.341,0.632
					C194.723,367.02,193.471,366.797,192.462,368.097z M212.045,356.015c-0.998-0.796-1.804,0.244-2.503,0.902
					C210.594,358.144,212.48,357.99,212.045,356.015z M194.819,402.568c-0.118-1.065-1.472-1.373-2.087-0.549
					C192.228,403.983,194.148,404.201,194.819,402.568z M186.177,382.927c0-0.359,0-0.63,0-0.911
					c-0.327-0.072-0.883-0.364-1.336-0.506c-0.233,0.453-0.463,0.899-0.691,1.345C184.278,383.795,185.088,383.568,186.177,382.927z
					 M208.322,369.885c-1.778-0.051-1.467,1.044-0.893,2.224C208.789,372.319,209.711,370.491,208.322,369.885z M198.194,402.564
					c0.043,0.058,0.085,0.113,0.128,0.171c0.14-0.265,0.283-0.536,0.427-0.811C198.542,402.039,198.352,402.243,198.194,402.564z
					 M187.244,398.041c0.631-0.202,1.354,0.142,1.803-0.512c-0.026-1.792-1.357-1.816-2.354-0.594
					C186.992,397.322,186.815,397.82,187.244,398.041z M192.47,390.955c0.306,0.549,0.829,0.944,1.217,1.363
					C196.262,391.565,194.14,388.922,192.47,390.955z M189.629,399.379c0.235,0.75,0.639,1.347,1.422,1.629
					c0.538-0.221,0.741-0.678,0.884-1.164C191.756,398.862,190.076,398.416,189.629,399.379z M212.07,373.048
					c-1.457-0.324-2.957-0.175-1.76,1.566C211.504,375.446,211.914,374.018,212.07,373.048z M204.186,391.094
					c-0.006,0.078-0.012,0.162-0.018,0.243c0.069-0.137,0.139-0.276,0.208-0.414C204.313,390.981,204.252,391.041,204.186,391.094z
					 M185.71,394.951c1.237-0.844,0.535-1.334-0.373-2.005C183.483,393.286,184.037,395.004,185.71,394.951z M209.888,362.498
					c0.049,0.303,0.104,0.643,0.151,0.937c1.323,0.56,1.627-1.223,2.074-2.028C211.044,360.566,210.499,361.709,209.888,362.498z
					 M183.881,406.125c0.844-0.105,1.233,0.694,1.893,0.295c0.137-0.385,0.264-0.746,0.394-1.115
					c-0.296-0.633-0.896-0.701-1.499-0.834C184.183,404.902,184.172,405.519,183.881,406.125z M211.989,350.734
					c-0.128-1.017-1.265-1.147-1.99-0.625C209.712,352.158,211.006,352.365,211.989,350.734z M204.898,378.552
					c-0.246,0.305-0.517,0.637-0.799,0.985c0.074,0.272,0.145,0.53,0.21,0.765c1.732,0.218,1.868,0.174,2.218-0.691
					C205.808,379.436,205.759,378.406,204.898,378.552z M202.644,364.461c-0.267-0.057-0.52-0.186-0.788-0.218
					c-0.654,0.021-0.482,0.818-0.39,1.254c-0.096,1.01,1.063,0.886,1.631,0.547c-0.042-0.698-0.042-0.698,0.313-1.533
					C203.171,364.193,202.894,364.513,202.644,364.461z M192.589,373.851c0.152,0.232,0.302,0.459,0.523,0.796
					c0.424-0.021,0.804-0.04,1.139-0.057c0.838-1.023,0.061-1.684-0.945-1.972C192.718,372.907,192.677,373.384,192.589,373.851z
					 M207.401,364.612c-0.249,1.141,0.131,1.879,1.337,1.603C210.505,365.444,208.679,364.169,207.401,364.612z M190.787,381.211
					c-0.752,0.221-0.713,0.898-0.92,1.428c0.09,0.172,0.18,0.344,0.283,0.543c0.429,0.02,0.852,0.038,1.293,0.058
					C192.381,382.328,191.575,381.746,190.787,381.211z M196.85,401.01c0.695-0.375,0.716-1.075,1.104-1.702
					c-0.359-0.234-0.656-0.502-1.183-0.331c-0.026,0.304-0.046,0.534-0.078,0.913C195.097,398.339,195.085,400.77,196.85,401.01z
					 M207.351,381.701c0.041,0.227,0.073,0.406,0.113,0.628c-0.206,0.136-0.421,0.278-0.708,0.468
					c0.322,0.342,0.705,0.843,1.147,0.625c0.094-0.131,0.217-0.211,0.349-0.276c0.206-0.416,0.412-0.832,0.617-1.248
					C208.499,381.557,207.903,381.483,207.351,381.701z M202.881,383.295c1.845-0.923-1.113-2.979-1.541-0.238
					C202.135,383.56,202.135,383.56,202.881,383.295z M189.029,385.429c-0.094-0.304-0.174-0.56-0.275-0.89
					C185.655,384.065,186.587,387.614,189.029,385.429z M197.593,393.773c-0.271-0.179-0.561-0.369-0.855-0.563
					c-0.484,0.291-0.914,0.549-1.391,0.835C195.69,395.472,197.886,395.367,197.593,393.773z M195.484,370.879
					c-0.122,1.049,1.078,1.531,1.816,0.801c0.05-0.332,0.094-0.633,0.151-1.016C196.427,370.047,196.312,370.06,195.484,370.879z
					 M191.947,405.997c-0.556-0.956-1.996-1.346-2.27,0.038C190.6,407.339,191.003,407.139,191.947,405.997z M211.942,367.996
					c-0.309-0.46-0.562-0.848-1.042-0.98c-0.645,0.376-0.836,0.976-1.08,1.6c0.362,0.266,0.589,0.358,1.007,0.51
					C211.207,368.741,211.563,368.379,211.942,367.996z M193.082,396.21c-0.166,0.536-0.279,0.9-0.382,1.23
					C194.25,399.554,195.178,396.063,193.082,396.21z M198.921,390.302c-0.578,0.293-0.46,0.742-0.414,1.264
					c0.551,0.148,0.987,0.473,1.533,0.4c-0.267-0.484-0.267-0.484-0.207-1.35C199.525,390.511,199.201,390.398,198.921,390.302z
					 M190.908,404.558c0.052,0.357,0.258,0.258,0.542,0.219C191.366,404.368,191.193,404.539,190.908,404.558z"
                            />
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M270.035,284.664c18.088,0.17,36.159,1.296,54.129,3.374c0.798,0.092,0.791-1.159,0-1.25
						c-17.97-2.077-36.04-3.204-54.129-3.374C269.231,283.407,269.23,284.657,270.035,284.664L270.035,284.664z"
                                />
                            </g>
                        </g>
                    </g>
                    <g>
                        <path
                            style={{ fill: secondary }}
                            d="M294.952,256.611c0,0-3.294-1.119-1.772-5.664c1.522-4.544,4.945-17.169,4.945-17.169
				s-4.149-15.037-0.619-24.045c3.53-9.008,32.503-32.591,32.503-32.591l21.148-2.521c0,0,5.16,56.389,3.439,73.341
				C352.875,264.915,294.952,256.611,294.952,256.611z"
                        />
                    </g>
                    <g style={{ opacity: 0.3 }}>
                        <path
                            style={{ fill: quaternary }}
                            d="M340.153,237.23c1.229-1.326,3.598,2.328,0.024,2.292
				C338.702,239.502,339.503,237.855,340.153,237.23z M345.814,222.281l-0.093-0.833l-0.373-0.04
				C345.415,221.759,345.585,222.076,345.814,222.281z M327.444,257.982c-0.581-0.258-1.034-0.979-1.916-0.572
				c-0.189,0.291-0.399,0.615-0.648,0.998C325.492,260.292,327.947,260.364,327.444,257.982z M347.079,233.599l-0.244-2.184
				c-0.648-0.395-1.285-0.089-1.708,1.143C345.174,233.702,346.319,233.945,347.079,233.599z M347.592,266.238
				c-0.292-0.148-0.642-0.326-1.103-0.56C343.081,267.976,348.235,270.154,347.592,266.238z M338.787,254.05
				c1.224-1.415-0.064-2.845-1.689-2.025C335.578,252.757,337.633,254.683,338.787,254.05z M332.275,228.271
				c-0.944,0.387-0.944,0.387-1.326,1.375c0.15,0.385,0.353,0.775,0.565,1.152C333.485,231.291,334.351,228.646,332.275,228.271z
				 M340.888,233.969c0.369-0.304,0.703-0.579,1.027-0.845c0.078-0.362,0.153-0.706,0.238-1.099
				C340.227,230.127,338.167,233.465,340.888,233.969z M334.112,300.681c-0.834,1.674,1.42,3.546,2.293,1.412
				C335.595,300.409,335.395,300.289,334.112,300.681z M337.186,240.152c-0.04,0.384-0.201,0.747-0.45,1.032
				c0.416,0.901,0.416,0.901,1.514,1.412c0.789-0.526,1.133-1.254,1.023-2.189C338.126,239.957,338.126,239.957,337.186,240.152z
				 M336.777,229.8c-0.271,0.83,1.078,1.199,1.706,1.283c0.522-0.824,0.944-1.588,0.231-2.518
				C337.117,228.421,337.117,228.421,336.777,229.8z M346.245,274.287c1.583-0.668,2.293-1.26,0.815-2.557
				c-0.697-0.113-1.146,0.322-1.637,0.57c-0.104,0.342-0.193,0.638-0.284,0.938C345.496,273.652,345.817,274.046,346.245,274.287z
				 M342.695,228.697c0.252,0.54,0.177,0.852-0.183,1.275c-0.081,0.947,1.141,0.958,1.799,0.906
				C346.025,229.968,344.183,227.668,342.695,228.697z M332.109,222.49c-2.039,0.276-1.061,3.334,0.793,2.521
				c0.19-0.359,0.41-0.773,0.65-1.227C333.042,223.314,332.686,222.811,332.109,222.49z M334.408,231.47
				c-0.212,0.477-0.716,0.816-0.771,1.482c0.884,1.323,2.582,0.83,2.822-0.713C335.84,231.767,335.341,231.146,334.408,231.47z
				 M345.415,256.175c0.958,1.066,1.904,0.527,2.524-0.57C347.136,253.71,345.076,253.999,345.415,256.175z M327.528,240.806
				c-0.145-0.238-0.311-0.43-0.496-0.566c-0.155,0.755-0.312,1.522-0.469,2.297C327.047,242.379,327.288,241.865,327.528,240.806z
				 M348.375,253.214c0.195,0.328,0.569,0.539,0.967,0.626l-0.243-2.171C348.478,251.776,348.015,252.294,348.375,253.214z
				 M330.638,232.462c-0.271-0.754-1.073-1.263-1.702-1.194c-0.141,0.641-0.284,1.301-0.431,1.98
				C329.24,233.826,330.736,233.557,330.638,232.462z M331.974,234.035c-1.335,0.232-1.399,2.333-0.023,2.578
				C333.796,236.326,333.548,234.191,331.974,234.035z M337.913,280.071c-1.581,1.291-1.282,3.117,0.593,2.816
				C339.68,282.052,338.906,280.716,337.913,280.071z M341.234,225.445c-0.304,0.711-0.776,0.615-1.197,0.469
				c-0.52,0.508-0.504,1.091-0.526,1.666C341.103,228.887,343.196,226.814,341.234,225.445z M338.321,257.289
				c-3.369,0.489-0.376,4.556,0.989,1.246C339.14,258.023,338.925,257.56,338.321,257.289z M323.391,272.006
				c-1.713-0.342-1.216,2.206,0.185,2.277c0.426-0.315,0.807-0.597,1.25-0.926C324.599,271.819,324.861,271.966,323.391,272.006z
				 M342.748,234.46c-0.185,0.727,0.299,1.357,0.24,2.063c0.494,0.16,0.907,0.15,1.372,0.028c0.057-0.566,0.26-1.048,0.646-1.419
				C344.783,234.042,343.457,233.66,342.748,234.46z M342.269,224.001c0.247,0.367,0.468,0.693,0.728,1.08
				C347.152,225.661,343.119,220.49,342.269,224.001z M333.296,293.583c0.413-2.228-1.978-2.163-2.349-0.642
				C331.045,294.395,332.455,294.603,333.296,293.583z M345.394,289.586c0.055,1.554,0.465,2.017,1.996,1.713
				c0.125-0.475,0.234-0.887,0.338-1.278C346.891,288.591,346.506,288.514,345.394,289.586z M342.597,304.437
				c0.672-0.258,1.32-0.555,1.927-0.899C343.929,303.003,342.822,303.322,342.597,304.437z M344.284,245.858
				c-3.775-0.417-0.271,4.59,0.745,1.202C344.748,246.601,344.686,246.186,344.284,245.858z M330.636,283.839
				c-0.784-0.681-2.479-1.059-2.573,0.425C328.184,286.061,330.911,285.625,330.636,283.839z M331.394,299.734
				c1.413,0.252,2.559-0.339,1.699-1.857C332.215,296.499,330.143,298.765,331.394,299.734z M331.23,251.876
				c-0.737,0.745,0.382,1.965,1.15,2.235c1.177-0.673,0.913-1.423,0.533-2.365C332.588,250.976,331.694,251.459,331.23,251.876z
				 M332.471,271.425c1.482-1.089,0.616-3.341-1.155-2.359C330.425,269.897,331.323,271.127,332.471,271.425z M347.519,249.207
				c-1.38-1.33-2.02,0.161-2.442,1.41C346.196,251.516,348.285,250.719,347.519,249.207z M333.371,281.819
				c0.023-1.518-1.892-2.19-2.269-0.589C330.465,282.932,332.74,283.129,333.371,281.819z M345.978,283.043
				c-0.502,0.607-0.555,1.269-0.609,1.938c0.497,0.564,1.081,0.741,1.822,0.524c0.007-0.693,0.186-1.098,0.566-1.697
				C347.137,283.539,346.834,282.77,345.978,283.043z M341.727,272.302c-0.883-2.057-2.302,0.219-2.137,1.525
				C340.926,274.409,341.98,273.782,341.727,272.302z M345.72,239.561c0.728-0.227,1.475-0.236,1.939-0.77l-0.127-1.137
				C346.431,236.431,344.764,237.64,345.72,239.561z M341.933,244.305c-0.129-1.139-0.934-1.171-1.849-1.368
				c-0.756-0.086-0.595,0.994,0.143,0.939C338.065,245.099,342.114,246.063,341.933,244.305z M336.947,246.394
				c-0.97,1.439,1.596,2.461,2.326,0.973C339.009,246.015,338.127,245.277,336.947,246.394z M324.096,256.711
				c0.274-0.461,0.531-0.894,0.814-1.367c-0.115-0.203-0.247-0.436-0.377-0.664c-0.113-0.007-0.214-0.007-0.323-0.013
				c-0.131,0.708-0.261,1.416-0.389,2.123C323.91,256.779,324.001,256.758,324.096,256.711z M333.328,275.777
				c-0.169-1.217-2.296-1.862-2.213-0.25c0.039,0.213-0.193,0.475-0.275,0.654C331.521,277.446,333.001,277.082,333.328,275.777z
				 M340.942,300.543c-0.581,0.35-1.327,0.578-1.56,1.41C340.033,304.572,343.766,301.064,340.942,300.543z M344.798,257.951
				c-0.477-0.438-1-0.434-1.615-0.521c-0.34,0.37-0.742,0.636-0.941,1.099C343.076,260.118,345.312,260.048,344.798,257.951z
				 M327.285,297.998c-0.482-0.419-1.064-0.311-1.491,0.012c0.337,0.587,0.669,1.16,0.992,1.717
				C327.616,299.463,328.191,298.868,327.285,297.998z M347.621,277.779c-1.153-0.576-1.894-0.254-2.321,0.977
				c0.679,0.541,1.747,1.805,2.278,0.477C347.77,278.77,348.297,278.106,347.621,277.779z M337.615,222.744
				c-1.022-0.043-1.009,0.796-0.766,1.446C338.078,226.737,340.636,223.113,337.615,222.744z M335.272,242.677
				c-0.381,0.191-0.764,0.384-1.147,0.576c-0.76,1.912,0.463,2.089,2.043,1.629C336.31,243.958,336.162,243.193,335.272,242.677z
				 M325.61,263.208c-0.212,0.416-0.425,0.834-0.669,1.314C327.31,266.77,329.339,262.891,325.61,263.208z M333.687,256.035
				c0.717,0.682,1.511,0.578,2.311,0.432C336.985,254.07,333.996,253.735,333.687,256.035z M337.277,292.001
				c-1.06,2.243,0.748,2.982,2.07,1.01C339.153,292.14,338.037,291.577,337.277,292.001z M335.802,294.808
				c-0.699,0.152-1.408,0.16-1.832,0.971C334.118,298.499,337.882,296.387,335.802,294.808z M333.853,249.628
				c0.261,0.519,0.355,1.618,1.234,1.236c0.622-0.187,1.158,0.064,1.357-0.774C336.112,248.582,334.66,248.13,333.853,249.628z
				 M346.863,260.116c-1.335,0.031-2.363,2.305-0.266,2.415C347.874,262.282,348.229,260.508,346.863,260.116z M323.331,288.98
				c-0.625,0.264-0.847,0.762-1.052,1.395c0.746,1.737,2.247,1.188,2.384-0.515C324.326,289.329,323.858,289.082,323.331,288.98z
				 M325.126,292.288c-0.041,0.729,0.253,1.772,1.095,1.707C328.734,293.724,327.038,290.517,325.126,292.288z M344.373,270.943
				c0.8-0.627,0.391-2.096-0.53-2.133c-1.02-0.184-1.02,0.555-1.322,1.383C342.291,271.257,343.808,270.892,344.373,270.943z
				 M328.123,300.98c-0.434,1.205,0.538,1.397,1.358,1.879c0.992-0.469,0.992-0.469,0.892-1.63
				C329.456,300.731,329.116,300.038,328.123,300.98z M342.335,276.05c0.274,0.245,0.569,0.508,0.9,0.803
				C346.996,276.85,343.322,272.406,342.335,276.05z M343.542,254.105c1.328-0.463,2.068-1.275,0.896-2.307
				C342.628,251.307,342.096,253.128,343.542,254.105z M329.353,245.485c0.615-0.442,1.172-0.933,1.238-1.798
				C328.997,241.602,326.988,244.899,329.353,245.485z M348.636,247.527l-0.125-1.116c-0.093,0.152-0.171,0.333-0.224,0.553
				C348.388,247.188,348.508,247.369,348.636,247.527z M332.673,245.826c-0.942-0.345-1.387,0.583-1.894,1.199
				c0.477,0.295,0.477,0.295,0.645,0.596c0.606,1.199,1.791,0.132,2.144-0.688C333.257,246.548,332.964,246.186,332.673,245.826z
				 M344.865,241.695c0.104-1.052-1.158-2.214-2.092-1.377C341.59,241.679,343.774,242.908,344.865,241.695z M341.682,260.346
				c-1.563-0.193-2.109,0.559-1.859,2.105C341.278,263.134,342.628,261.455,341.682,260.346z M328.794,260.339
				c-1.642,1.702,0.433,3.057,1.936,1.293C330.534,260.945,329.523,260.008,328.794,260.339z M326.541,286.086
				c-0.435,0.57-1.194,0.564-1.539,1.125C325.573,289.823,329.821,286.996,326.541,286.086z M341.454,295.108
				c-1.895-0.925-2.266,1.905-0.437,2.233C342.157,296.879,342.328,295.681,341.454,295.108z M336.19,227.343
				c0.058-0.786,0.071-1.484-0.583-1.887c-1.502,0.277-2.312,0.976-1.041,2.201C335.445,227.772,335.445,227.772,336.19,227.343z
				 M349.809,288.117c0.063-0.685,0.121-1.378,0.174-2.075c-0.034-0.038-0.073-0.082-0.106-0.119
				C347.772,286.343,347.828,288.931,349.809,288.117z M347.405,244.828c0.074-0.377,0.149-0.755,0.222-1.124
				C345.306,241.106,344.026,246.27,347.405,244.828z M335.816,279.533c1.384-1.284-0.82-2.445-2.049-1.848
				C333.891,279.361,334.017,279.623,335.816,279.533z M343.705,265.64c0.684-0.34,0.958-0.879,1.064-1.596
				c-0.322-1.026-1.806-1.461-2.256-0.293C342.212,264.585,343.337,265.053,343.705,265.64z M338.218,299.955
				c1.795-0.59,0.537-2.158-0.783-2.39C336.303,298.49,336.835,299.823,338.218,299.955z M328.261,279.103
				c0.546,1.693,3.042,0.264,1.817-1.115C329.243,277.298,327.595,277.926,328.261,279.103z M345.924,227.933
				c0.162-0.067,0.331-0.116,0.503-0.162l-0.235-2.106C345.244,226.077,345.077,227.089,345.924,227.933z M327.466,269.388
				c-1.253-1.245-3.181,0.047-1.868,1.504C327.009,271.111,327.716,270.541,327.466,269.388z M333.546,304.821
				c-0.763-2.281-1.602-1.159-2.907-0.146C331.381,305.675,332.992,305.993,333.546,304.821z M338.268,303.445
				c-1.91,0.061-1.966,1.344-0.612,2.235c0.241-0.036,0.484-0.074,0.728-0.117C339.247,304.992,339.335,304.1,338.268,303.445z
				 M326.628,251.582c-1.408,0.609-1.973,2.315-0.105,2.549C327.515,253.495,328.206,252.008,326.628,251.582z M335.256,265.947
				c-0.413,0.06-0.864,0.15-0.907,0.516c-0.053,0.451-0.614,0.401-0.621,0.834c0.341,1.365,2.314,0.944,2.701-0.133
				C336.251,266.549,335.582,266.404,335.256,265.947z M348.245,293.158c0.216,0.433,0.522,0.704,0.841,0.852
				c0.107-0.664,0.208-1.346,0.3-2.053C348.79,291.828,348.356,292.26,348.245,293.158z M343.772,291.871
				c-2.643,0.716,0.058,3.998,1.299,1.135C344.464,292.163,344.464,292.163,343.772,291.871z M337.668,262.968
				c-1.702,0.78-0.424,3.199,1.085,2.137C338.943,264.148,338.915,262.998,337.668,262.968z M330.38,227.48
				c0.286-0.628,0.097-1.134-0.269-1.426c-0.138,0.591-0.281,1.216-0.431,1.876C329.922,227.865,330.165,227.728,330.38,227.48z
				 M343.95,297.423c-2.087,0.491-1.307,2.177,0.284,2.57c0.297-0.452,0.555-0.844,0.834-1.27
				C344.668,298.285,344.535,297.703,343.95,297.423z M344.747,287.329c-0.304-0.609-0.123-1.23-0.901-1.476
				c-0.456,0.496-0.789,1.045-1.427,1.262C342.317,288.552,345.188,289.042,344.747,287.329z M335.621,291.315
				c0.076-0.561,0.28-1.011,0.709-1.343c-0.177-0.504-0.432-0.832-0.889-0.984c-0.414,0.107-0.828,0.213-1.277,0.328
				c-0.113,0.227-0.235,0.469-0.397,0.787C334.253,290.802,334.732,291.341,335.621,291.315z M339.604,255.717
				c0.26,0.82,1.565,1.139,2.311,0.457C342.508,254.402,339.464,253.777,339.604,255.717z M330.087,256.045
				c0.008-0.651,0.282-1.137-0.36-1.639c-0.559,0.186-1.159,0.249-1.618,0.688c0,0.441,0,0.87,0,1.346
				C328.823,256.578,330.394,257.275,330.087,256.045z M335.881,222.146c0.412-0.998,0.427-1.364,0.042-1.758l-1.507-0.163
				C333.577,220.984,334.408,222.79,335.881,222.146z M324.567,295.855c0.09,0.159,0.179,0.318,0.269,0.476
				c0.024-0.034,0.043-0.062,0.068-0.097C324.785,296.108,324.679,295.974,324.567,295.855z M336.121,261.5
				c-0.246-0.409-0.488-0.813-0.795-1.325C332.613,259.906,334.856,264.885,336.121,261.5z M349.494,259.871
				c0.212-0.169,0.361-0.288,0.478-0.394l-0.209-1.87c-0.553-0.146-1.184,0.248-1.551,0.912
				C348.363,259.275,348.718,259.775,349.494,259.871z M341.111,277.998c-0.449-0.51-0.797-0.178-1.153,0.016
				c-0.951,0.582,0.084,1.497,0.561,2.011c1.208-0.074,1.164-0.918,1.679-1.802C341.761,278.143,341.289,278.2,341.111,277.998z
				 M323.614,266.168c-0.812,0.001-1.026,0.175-1.15,0.893C322.461,270.255,326.666,266.145,323.614,266.168z M321.531,270.568
				c0.052-0.204,0.069-0.376,0.07-0.533c-0.027,0.18-0.053,0.359-0.08,0.538C321.524,270.571,321.527,270.57,321.531,270.568z
				 M322.101,286.996c-0.641-0.517-1.281-0.703-1.99-0.741c0.057,0.848,0.175,1.494,0.365,1.894
				c0.006,0.013,0.013,0.026,0.019,0.039c0.302-0.02,0.589-0.04,0.857-0.057C321.6,287.753,321.816,287.427,322.101,286.996z
				 M324.636,283.836c-1.375-0.769-2.876-0.173-1.96,1.527C323.963,285.478,325.066,285.379,324.636,283.836z M350.557,264.722
				c-0.234-0.579-0.061-1.309-0.864-1.606c-2.559,0.763-1.264,3.247,0.865,1.695C350.557,264.754,350.557,264.73,350.557,264.722z
				 M335.418,237.109c-0.681,0.256-1.281,0.576-1.572,1.284c0.43,0.669,1.092,0.844,1.685,1.108
				C336.527,239.018,336.484,237.567,335.418,237.109z M328.082,238.099c0.176,1.006,0.688,1.313,1.486,1.307
				C331.641,238.382,329.348,236.057,328.082,238.099z M328.302,249.851h0.002c-0.226,1.358,1.654,1.008,2.099,0.299
				C331.023,248.535,328.11,248.037,328.302,249.851z M341.237,250.947c0.25-0.202,0.487-0.394,0.711-0.575
				c-0.11-0.323-0.2-0.584-0.274-0.798c-0.49-0.311-0.944-0.6-1.457-0.926C338.837,249.648,339.769,250.965,341.237,250.947z
				 M335.742,273.756c0.151-0.493,0.287-0.939,0.459-1.497c-0.371-0.252-0.693-0.472-1.021-0.695
				c-0.764,0.324-1.276,0.799-1.285,1.678C334.349,274.038,335.049,273.886,335.742,273.756z M327.845,295.95
				c1.033,1.059,1.075,1.067,2.324,0.515c0.053-0.412,0.107-0.834,0.164-1.281c-0.292-0.154-0.566-0.301-0.885-0.469
				C328.693,294.951,328.168,295.324,327.845,295.95z M349.306,282.845c0.567-0.031,0.647-0.673,0.951-1.075
				c0.018-0.352,0.035-0.702,0.051-1.052c-0.404-0.213-1.115-0.246-1.508-0.286c-0.346,0.25-0.463,0.622-0.605,1.096
				C348.901,281.813,348.684,282.603,349.306,282.845z M327.298,247.727c0-0.403,0-0.87,0-1.505
				c-0.483-0.167-0.914-0.317-1.378-0.477c-0.114,0.577-0.229,1.157-0.343,1.741c0.072,0.139,0.145,0.279,0.219,0.42
				C326.312,247.832,326.812,248.353,327.298,247.727z M331.117,264.263c1.389,1.85,1.717,0.948,2.406-0.73
				c-0.59-0.247-1.128-0.471-1.669-0.697C331.313,263.189,331.403,263.778,331.117,264.263z M340.74,222.46
				c0.878-0.446,1.172-0.981,1.141-1.427l-2.046-0.221c-0.089,0.159-0.17,0.338-0.229,0.563
				C339.949,221.789,340.277,222.177,340.74,222.46z M341.571,289.283c-0.819,0.101-2.207,0.269-1.953,1.385
				C340.404,291.897,343.398,290.702,341.571,289.283z M330.072,265.929c-3.017-0.088-1.395,4.15,0.413,1.5
				C330.345,266.919,330.222,266.472,330.072,265.929z M328.124,272.357c0.294,1.172,0.294,1.172,0.792,1.703
				c0.545-0.407,1.039-0.777,1.525-1.141C330.032,271.402,329.074,271.534,328.124,272.357z M336.755,235.849
				c0.495,0.372,0.925,0.695,1.373,1.031c0.877-0.418,0.672-1.196,0.977-1.811c-0.474-0.246-0.808-0.446-1.216-0.734
				C337,234.481,337.148,235.324,336.755,235.849z M347.691,296.288c-0.093-0.396-0.182-0.774-0.285-1.218
				C344.519,293.744,345.036,298.477,347.691,296.288z M333.321,241.55c-0.405-1.605-2.016-2.349-2.329-0.281
				C331.269,242.504,332.61,242.174,333.321,241.55z M324.709,278.453c-0.074,0.051-0.148,0.102-0.222,0.152
				c-0.245-0.355-0.491-0.711-0.742-1.075C320.602,278.017,324.273,281.695,324.709,278.453z M328.189,290.011
				c0.164,0.341,0.329,0.684,0.515,1.068C332.028,291.848,329.969,287.18,328.189,290.011z M345.371,301.586
				c0.109,0.363,0.199,0.661,0.296,0.978c0.075,0.022,0.143,0.042,0.217,0.063c0.098-0.077,0.198-0.153,0.292-0.234
				c0.364-0.314,0.695-0.795,1.003-1.391c-0.209-0.195-0.448-0.365-0.715-0.534C346.069,300.871,345.714,301.234,345.371,301.586z
				 M325.265,276.361c0.778,0.524,2.251,0.59,2.305-0.587C327.525,274.652,324.46,274.466,325.265,276.361z M350.334,276.314
				c0.309-0.712-0.875-2.174-1.594-1.555c0.063,0.589-0.338,0.99-0.797,1.497C348.515,276.971,349.665,276.889,350.334,276.314z
				 M341.3,283.27c-2.476-0.698-1.303,3.421,0.447,1.952C341.509,284.589,341.97,283.875,341.3,283.27z M320.476,282.905
				c1.131-0.455,1.323-1.249,0.737-2.251c-0.378-0.122-0.683-0.129-0.924-0.063c-0.061,0.717-0.111,1.397-0.148,2.033
				C320.24,282.725,320.349,282.821,320.476,282.905z M332.382,285.867c-0.635,0.438-1.127,0.91-1.186,1.801
				C333.333,288.534,334.589,287.435,332.382,285.867z M338.478,274.997c-0.47-0.859-1.139,0.482-1.766,0.715
				c0.56,0.557,0.56,0.557,0.598,1.232C338.679,277.271,339.732,276,338.478,274.997z M323.205,260.237
				c-0.086,0.49-0.171,0.979-0.255,1.465c0.663,0.38,1.563,0.432,1.968-0.078C324.804,260.646,323.914,259.939,323.205,260.237z
				 M325.058,281.408c0.281,0.455,0.467,0.992,1.12,1.318c0.409-0.223,0.786-0.428,1.159-0.631c-0.043-0.526-0.081-0.984-0.123-1.49
				C326.421,280.74,325.561,280.5,325.058,281.408z M331.232,258.853c1.29,0.813,2.743-0.087,1.754-1.487
				C331.469,257.246,331.203,257.468,331.232,258.853z M341.172,266.476c-0.626,0.14-1.083,0.04-1.467,0.711
				c0.041,1.349,0.996,1.201,1.906,0.71C341.81,267.233,342.368,266.171,341.172,266.476z M335.212,283.257
				c-0.79,0.371-0.574,1.245-1.089,1.846c0.296,0.173,0.525,0.308,0.742,0.434c0.597-0.221,1.161-0.418,1.325-1.151
				C335.98,283.972,335.847,283.455,335.212,283.257z M339.059,287.559c-0.202-0.51-0.215-0.74-0.317-1.426
				c-0.604,0.361-1.295-0.229-1.694,0.556c0.263,0.468,0.39,0.956,0.317,1.495C338.083,288.29,338.708,288.343,339.059,287.559z
				 M338.693,269.861c0.113-0.08,0.105-0.369,0.108-0.563c-0.526-1.209-2.231-0.08-1.781,1.07c0.415,0.239,0.923,0.741,1.48,0.524
				C338.603,270.52,338.283,270.148,338.693,269.861z M321.177,274.654c-0.081-0.013-0.151-0.01-0.226-0.014
				c-0.082,0.625-0.159,1.237-0.23,1.832c0.3-0.07,0.66-0.26,1.081-0.64C321.572,275.398,321.373,275.021,321.177,274.654z
				 M350.301,270.586c0.093-1.061,0.093-1.061-0.435-1.608C347.326,269.223,348.307,271.336,350.301,270.586z M343.087,280.883
				c-0.35,0.646,0.214,1.109,0.111,1.709C345.481,283.231,344.841,280.15,343.087,280.883z"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M297.747,256.512c-1.801-0.381-3.38-1.696-3.824-3.532c-0.46-1.906,0.639-3.93,1.483-5.576
					c1.751-3.413,2.991-6.67,3.199-10.545c0.396-7.343-1.965-14.535-1.364-21.886c0.319-3.902,1.52-7.736,4.299-10.59
					c0.562-0.577-0.321-1.462-0.884-0.884c-5.158,5.296-5.202,13.255-4.366,20.137c0.533,4.384,1.303,8.79,1.064,13.223
					c-0.117,2.177-0.509,4.343-1.306,6.378c-0.786,2.005-1.992,3.814-2.783,5.818c-0.73,1.85-1.02,3.715-0.053,5.536
					c0.858,1.615,2.423,2.752,4.201,3.128C298.2,257.883,298.535,256.678,297.747,256.512L297.747,256.512z"
                            />
                        </g>
                    </g>
                    <g>
                        <path
                            style={{ fill: "#FFBFBF" }}
                            d="M317.134,188.054c0,0-1.335,7.998,7.624,6.476c8.959-1.521,16.132-10.265,16.132-10.265
				l5.285-12.573l-16.171,4.577L317.134,188.054z"
                        />
                    </g>
                    <g>
                        <path
                            style={{ fill: "#FFBFBF" }}
                            d="M333.974,122.533c-12.199-8.53-29.473-0.74-31.197,14.045c-0.546,4.676,0.471,10.266,3.117,17.273
				c7.383,19.551,24.057,13.662,24.057,13.662l0.078,12.816l18.24-5.098l-4.078-23.225c0,0-1.397-15.822-5.916-24.676
				C337.269,125.361,335.76,123.782,333.974,122.533z"
                        />
                    </g>
                    <g>
                        <path
                            style={{ fill: quaternary }}
                            d="M362.868,159.927l-7.22-21.113c-2.564-7.497-7.14-14.209-13.426-19.033
				c-2.803-2.152-6.06-4.029-9.685-5.068c-12.687-3.636-24.581,7.347-24.581,7.347l6.339-2.792
				c7.978,10.291,22.395,10.291,22.395,10.291l5.948,38.943c0,0-10.79,8.166-10.876,14.928c-0.086,6.762,1.03,12.564,1.03,12.564
				s-5.532,10.905-6.293,16.76c-0.761,5.856,3.291,8.312,12.163,11.395c8.383,2.913,7.212-1.663,7.058-2.176l9.078,28.948
				l3.688-18.098c0,0,15.706-5.852,20.353-14.558c4.647-8.705-0.947-20.986-0.947-20.986s3.003-6.066,4.39-12.709
				C383.669,177.927,362.868,159.927,362.868,159.927z"
                        />
                    </g>
                    <g>
                        <path
                            style={{ fill: quaternary }}
                            d="M310.471,140.055c0.242,0.727,0.969,1.14,1.624,0.922c0.655-0.218,0.991-0.984,0.749-1.71
				c-0.241-0.727-0.969-1.14-1.624-0.922C310.565,138.562,310.23,139.328,310.471,140.055z"
                        />
                    </g>
                    <g>
                        <path
                            style={{ fill: quaternary }}
                            d="M323.571,135.308c0.242,0.727,0.969,1.14,1.624,0.922c0.655-0.218,0.991-0.984,0.749-1.71
				c-0.242-0.727-0.969-1.139-1.624-0.922C323.665,133.815,323.33,134.581,323.571,135.308z"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M327.964,126.164c-3.004-0.974-7.01-1.05-9.331,1.548c-0.537,0.601,0.129,1.428,0.841,1.09
					c1.278-0.607,2.339-1.387,3.749-1.718c1.419-0.333,3.108-0.5,4.54-0.188C328.239,127.001,328.43,126.315,327.964,126.164
					L327.964,126.164z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M322.732,147.533c-0.523,0.84-1.029,1.773-1.878,2.331c-0.892,0.587-1.84,0.666-2.877,0.592
					c-0.787-0.056-0.962,1.217-0.187,1.381c2.46,0.522,5.83-1.341,5.466-4.163C323.222,147.41,322.892,147.276,322.732,147.533
					L322.732,147.533z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M337.965,161.564c-3.134,2.725-6.481,4.758-10.417,6.083c-0.739,0.249-0.444,1.362,0.324,1.175
					c4.17-1.013,7.898-3.551,10.181-7.19C338.089,161.575,338.012,161.523,337.965,161.564L337.965,161.564z"
                            />
                        </g>
                    </g>
                    <g style={{ opacity: 0.3 }}>
                        <path
                            style={{ fill: quaternary }}
                            d="M323.018,141.694c0.358,1.078,2.336,1.391,4.417,0.699c2.081-0.692,3.478-2.126,3.12-3.204
				c-0.358-1.078-2.336-1.391-4.417-0.699C324.057,139.182,322.66,140.616,323.018,141.694z"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M313.793,119.151c-3.458-4.348-11.373-3.49-14.826,0.394c-0.334,0.376,0.097,1.028,0.562,0.729
					c2.313-1.486,4.46-2.733,7.3-2.774c2.516-0.036,4.571,0.997,6.882,1.793C313.799,119.322,313.84,119.21,313.793,119.151
					L313.793,119.151z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M302.121,113.855c5.221-0.742,10.227,1.593,12.767,6.217c0.383,0.698,1.455,0.074,1.069-0.625
					c-2.851-5.154-8.24-7.51-13.9-5.827C301.902,113.666,301.97,113.877,302.121,113.855L302.121,113.855z"
                            />
                        </g>
                    </g>
                    <g>
                        <path
                            style={{ fill: "#FFBFBF" }}
                            d="M346.175,134.471c0.929-0.925,2.419-1.144,3.664-0.733c1.245,0.41,2.254,1.368,2.954,2.477
				c1.163,1.843,1.529,4.255,0.638,6.244c-0.891,1.989-3.154,3.37-5.291,2.943"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M350.639,139.146c-0.327-0.335-0.48-0.651-0.965-0.799c-0.469-0.143-1.022-0.097-1.48,0.055
					c-0.846,0.281-1.637,1.026-1.828,1.917c-0.144,0.672,0.803,1.15,1.157,0.488c0.297-0.556,0.562-1.023,1.18-1.273
					c0.439-0.178,1.35-0.327,1.821-0.192C350.639,139.374,350.72,139.228,350.639,139.146L350.639,139.146z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M306.829,133.244c0.79-0.83,2.274-1.777,3.416-1.963c2.916-0.474,4.514,2.417,5.108,4.768
					c0.807,3.195,0.497,6.31,0.108,9.531c-0.1,0.829,1.214,0.961,1.427,0.193c1.202-4.332,1.006-11.323-2.586-14.589
					c-2.54-2.31-6.52-1.469-7.929,1.708C306.248,133.173,306.583,133.502,306.829,133.244L306.829,133.244z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: "#FFBFBF" }}
                                    d="M343.174,306.075c0,0-4.938,23.057-2.445,29.159s23.869,11.711,27.592,10.904
						c2.677-0.58,2.677-3.07,0.714-4.501c-1.119-0.815-8.235-3.806-8.235-3.806s25.543,0.193,27.04-1.217
						c1.497-1.41,3.865-4.293,0-4.838c-3.864-0.544-9.281-0.16-9.281-0.16s-0.889-3.346-7.152-3.101c0,0,0.126-2.369-4.492-2.973
						c-4.364-0.57-9.745,1.515-9.745,1.515l1.527-19.24L343.174,306.075z"
                                />
                            </g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: quaternary }}
                                        d="M366.381,332.702c6.898,0,13.795,0,20.693,0c0.804,0,0.806-1.25,0-1.25
							c-6.898,0-13.795,0-20.693,0C365.576,331.452,365.575,332.702,366.381,332.702L366.381,332.702z"
                                    />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: quaternary }}
                                        d="M371.702,327.848c-2.401-0.387-4.819-0.373-7.215,0.046c-0.333,0.058-0.52,0.466-0.437,0.769
							c0.097,0.351,0.435,0.495,0.769,0.437c2.158-0.377,4.387-0.394,6.551-0.046c0.333,0.054,0.673-0.086,0.769-0.436
							C372.22,328.319,372.037,327.902,371.702,327.848L371.702,327.848z"
                                    />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: quaternary }}
                                        d="M366.029,346.337c0.713-1.599,2.59-2.438,4.251-1.628c0.303,0.148,0.676,0.082,0.855-0.224
							c0.161-0.275,0.08-0.706-0.224-0.855c-1.14-0.556-2.471-0.644-3.65-0.151c-1.012,0.423-1.862,1.221-2.311,2.227
							c-0.138,0.308-0.089,0.672,0.224,0.855C365.441,346.717,365.891,346.647,366.029,346.337L366.029,346.337z"
                                    />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: quaternary }}
                                        d="M382.823,333.055c0.691,1.146,1.88,1.967,3.212,2.153c1.4,0.195,2.824-0.293,3.823-1.293
							c0.569-0.57-0.315-1.454-0.884-0.884c-0.715,0.716-1.761,1.103-2.772,0.948c-0.954-0.147-1.803-0.732-2.298-1.555
							C383.487,331.736,382.406,332.364,382.823,333.055L382.823,333.055z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <path
                                style={{ fill: secondary }}
                                d="M351.534,171.884c0,0,11.969-2.588,15.476,11.276c2.348,9.284-0.983,128.025-7.452,128.77
					c-7.099,0.817-20.309-0.712-25.419-1.381c-2.254-0.295-3.848-2.344-3.586-4.603l8.515-97.052
					C339.068,208.894,340.729,171.693,351.534,171.884z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <polygon style={{ fill: "#FFBFBF" }} points="270.144,196.566 274.559,211.199 267.761,209.551 266.437,204.038 				" />
                        </g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M265.133,183.45c0,0,12.607,41.648,18.101,44.318c5.494,2.67,25.251,1.12,25.517-1.91
					c0.267-3.029-12.38-43.811-16.001-45.112C289.129,179.445,263.563,178.244,265.133,183.45z"
                            />
                        </g>
                        <g>
                            <path
                                style={{ fill: "#FFBFBF" }}
                                d="M271.974,196.026c0,0,2.759-2.796,0.792-5.087c-1.967-2.291-6.259-1.269-8.34,4.477
					c-2.081,5.746-2.918,10.732,2.929,13.41c0,0-0.768,2.323,2.389,4.824c0,0-0.824,3.386,2.23,6.331c0,0,0.762,5.87,4.218,7.392
					c3.456,1.521,10.486,0.037,10.044-2.594c-0.442-2.631-7.326-4.045-7.326-4.045s5.498-0.666,6.341-2.924
					c0.843-2.258-2.411-4.939-2.411-4.939s1.989-1.464,1.163-3.972c-0.826-2.508-3.916-3.638-8.183-3.589
					c-4.267,0.048-7.619,1.092-7.619,1.092S269.697,197.187,271.974,196.026z"
                            />
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M267.621,207.9c3.204-1.755,6.816-2.61,10.467-2.497c0.805,0.025,0.804-1.225,0-1.25
						c-3.866-0.12-7.706,0.81-11.098,2.668C266.284,207.208,266.914,208.288,267.621,207.9L267.621,207.9z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M269.978,214.432c4.325-0.855,8.717-1.264,13.125-1.237c0.805,0.005,0.805-1.245,0-1.25
						c-4.518-0.028-9.026,0.405-13.458,1.281C268.857,213.382,269.191,214.587,269.978,214.432L269.978,214.432z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M272.461,220.65c3.433,0.95,7.054,1.008,10.518,0.18c0.782-0.187,0.451-1.393-0.332-1.205
						c-3.249,0.777-6.635,0.711-9.854-0.18C272.017,219.23,271.685,220.435,272.461,220.65L272.461,220.65z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M269.826,206.481c-0.026-3.19,0.861-6.31,2.552-9.014c0.428-0.684-0.653-1.312-1.079-0.631
						c-1.803,2.882-2.75,6.246-2.723,9.645C268.582,207.285,269.832,207.287,269.826,206.481L269.826,206.481z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M266.805,192.66c1.123,0.616,2.461,0.661,3.623,0.126c1.023-0.471,1.794-1.439,2.095-2.515
						c0.217-0.776-0.988-1.107-1.205-0.332c-0.212,0.757-0.725,1.38-1.456,1.737c-0.738,0.361-1.684,0.311-2.426-0.095
						C266.73,191.194,266.099,192.273,266.805,192.66L266.805,192.66z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M277.222,205.755c0.273,1.433,1.255,2.748,2.571,3.389c1.475,0.718,3.25,0.635,4.652-0.217
						c0.687-0.417,0.059-1.498-0.631-1.079c-1.034,0.628-2.382,0.738-3.473,0.176c-1.015-0.524-1.701-1.484-1.914-2.6
						c-0.063-0.332-0.462-0.521-0.769-0.437C277.311,205.082,277.158,205.423,277.222,205.755L277.222,205.755z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M279.734,212.678c0.151,2.68,2.588,4.977,5.294,4.877c0.337-0.012,0.625-0.278,0.625-0.625
						c0-0.329-0.286-0.638-0.625-0.625c-1.011,0.038-1.985-0.353-2.726-1.028c-0.421-0.384-0.672-0.705-0.933-1.207
						c-0.126-0.244-0.176-0.365-0.26-0.662c-0.035-0.125-0.065-0.251-0.088-0.379c-0.002-0.013-0.029-0.196-0.016-0.091
						c-0.01-0.087-0.016-0.173-0.021-0.26c-0.019-0.337-0.274-0.625-0.625-0.625C280.035,212.053,279.715,212.339,279.734,212.678
						L279.734,212.678z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M281.897,222.867c-0.232,2.121,1.75,4.165,3.866,4.055c0.326-0.017,0.641-0.275,0.625-0.625
						c-0.015-0.324-0.275-0.643-0.625-0.625c-0.112,0.006-0.224,0.007-0.337-0.001c-0.007,0-0.142-0.015-0.143-0.013
						c0.087,0.012,0.095,0.013,0.025,0.001c-0.016-0.003-0.031-0.006-0.047-0.008c-0.109-0.021-0.217-0.048-0.323-0.082
						c-0.045-0.014-0.09-0.03-0.135-0.046c-0.061-0.023-0.122-0.053-0.027-0.009c-0.189-0.088-0.37-0.187-0.541-0.306
						c-0.026-0.018-0.052-0.037-0.078-0.056c0.063,0.049,0.067,0.052,0.012,0.009c-0.037-0.031-0.074-0.061-0.11-0.093
						c-0.078-0.069-0.153-0.142-0.225-0.218c-0.072-0.076-0.14-0.156-0.205-0.237c0.096,0.12-0.004-0.009-0.017-0.028
						c-0.032-0.046-0.063-0.093-0.093-0.14c-0.055-0.088-0.107-0.179-0.153-0.271c-0.022-0.043-0.041-0.087-0.062-0.13
						c0.07,0.142-0.006-0.016-0.018-0.05c-0.036-0.097-0.066-0.196-0.092-0.296c-0.012-0.046-0.022-0.092-0.032-0.139
						c-0.004-0.018-0.033-0.204-0.02-0.105c0.013,0.099-0.007-0.089-0.008-0.106c-0.003-0.048-0.005-0.095-0.005-0.143
						c-0.001-0.113,0.006-0.224,0.018-0.336c0.036-0.326-0.313-0.639-0.625-0.625C282.152,222.259,281.935,222.518,281.897,222.867
						L281.897,222.867z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: primary }}
                                    d="M293.483,181.351c3.171,7.54,6.149,15.16,8.931,22.853c0.271,0.751,1.48,0.427,1.205-0.332
						c-2.781-7.693-5.759-15.313-8.931-22.853C294.381,180.288,293.171,180.609,293.483,181.351L293.483,181.351z"
                                />
                            </g>
                        </g>
                        <g>
                            <path
                                style={{ fill: white }}
                                d="M283.777,191.955c1.008,1.514,0.838,3.398-0.38,4.209c-1.217,0.81-3.021,0.24-4.029-1.274
					c-1.008-1.514-0.838-3.398,0.379-4.209C280.965,189.87,282.769,190.441,283.777,191.955z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <path
                                                style={{ fill: "#FFBFBF" }}
                                                d="M263.084,457.365c0.71-1.077,1.785-1.979,2.439-3.23c2.981-5.699,4.826-13.203,4.708-19.443
									c-0.144-7.61-17.833-30.23-18.705-30.622l30.637-4.85c0,0,6.845,23.997,10.689,31.068
									c3.844,7.072,17.126,22.994,16.58,28.407c-0.546,5.414-31.143,23.912-43.096,29.681
									c-18.569,8.962-28.512,3.388-29.567,0.968C236.045,487.685,259.138,463.347,263.084,457.365z"
                                            />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path
                                                style={{ fill: secondary }}
                                                d="M236.912,488.629c0.404-0.715,0.846-1.315,1.665-2.394c0,0-0.096-0.11,0,0
									c0.506,0.095,15.702,2.609,36.619-6.625c21.27-9.393,32.937-27.895,32.937-27.895c1.49,2.725,2.501,5.165,2.345,6.678
									c-0.017,0.173-0.068,0.355-0.14,0.548c-0.145,0.396-0.412,0.841-0.777,1.326c-5.29,7.01-31.775,22.908-42.748,28.204
									c-14.464,6.985-23.584,5.239-27.685,3C237.037,490.26,236.495,489.367,236.912,488.629z"
                                            />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            style={{ fill: primary }}
                                            d="M242.126,481.833l20.669-24.36c2.075-2.563,3.705-6.374,5.124-10.78c0,0-0.315-0.087,0,0
								c0.113-8.385,6.171-11.582,11.081-8.702c3.262,1.914,5.24,6.175,6.268,9.114c0.319-0.104,0.641-0.201,0.953-0.325
								c7.995-3.156,11.138-11.061,11.138-11.061c4.016,5.678,8.097,11.623,10.372,15.733c0,0-12.179,19.339-33.172,28.613
								c-20.993,9.274-36.294,6.318-36.294,6.318L242.126,481.833z"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path
                                                style={{ fill: quaternary }}
                                                d="M280.316,455.854c-2.116-0.55-4.005-1.735-6.139-2.304c-2.361-0.63-4.705-0.743-7.138-0.672
									c-1.263,0.037-1.406-1.712-0.179-1.939c4.309-0.797,11.257,0.435,13.663,4.58
									C280.606,455.662,280.515,455.906,280.316,455.854L280.316,455.854z"
                                            />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path
                                                style={{ fill: quaternary }}
                                                d="M272.621,463.32c-3.553-1.038-6.725-3.043-10.583-2.386
									c-1.263,0.215-1.666-1.735-0.499-2.167c2.107-0.78,4.534-0.326,6.567,0.481c1.811,0.719,3.993,1.887,4.771,3.761
									C272.94,463.16,272.792,463.371,272.621,463.32L272.621,463.32z"
                                            />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path
                                                style={{ fill: quaternary }}
                                                d="M265.714,470.699c-1.649-0.391-2.993-1.613-4.55-2.268c-1.797-0.755-3.535-0.686-5.413-0.944
									c-1.462-0.201-1.15-2.117,0.091-2.389c3.297-0.724,9.345,1.355,10.54,4.79C266.525,470.296,266.178,470.809,265.714,470.699
									L265.714,470.699z"
                                            />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path
                                                style={{ fill: white }}
                                                d="M292.369,456.826c-2.636,3.102-5.887,5.46-9.459,7.385c-0.855,0.461-1.676-1.038-0.82-1.518
									c3.412-1.914,6.567-4.148,9.724-6.451C292.182,455.974,292.671,456.471,292.369,456.826L292.369,456.826z"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            style={{ fill: quaternary }}
                                            d="M307.719,452.656c-11.828,18.328-30.901,30.743-52.374,34.446
								c-5.618,0.969-11.61,1.689-17.15-0.035c-1.067-0.332-0.34-1.913,0.716-1.584c4.939,1.537,10.384,0.843,15.396,0.033
								c5.13-0.829,10.172-2.16,15.038-3.983c9.644-3.613,18.556-9.185,25.944-16.371c4.207-4.092,7.897-8.672,11.078-13.601
								C306.975,450.62,308.32,451.725,307.719,452.656L307.719,452.656z"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g>
                            <path
                                style={{ fill: primary }}
                                d="M247.138,315.665c10.44,2.106,24.034,27.375,24.034,27.375s22.806,60.785,22.933,69.574
					c0.127,8.789-38.139,21.235-44.143,18.598c-6.004-2.637-33.172-79.566-33.172-97.73c0-0.541,2.721-18.848,20.018-21.363
					C244.924,310.939,247.138,315.665,247.138,315.665z"
                            />
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M250.404,430.77c-2.319-2.811-3.842-6.401-5.334-9.689c-2.345-5.166-4.421-10.454-6.427-15.759
						c-4.239-11.208-8.112-22.555-11.866-33.933c-3.067-9.297-6.471-18.6-7.589-28.381c-0.907-7.932-0.079-16.065,5.094-22.484
						c2.25-2.791,5.158-5.05,8.476-6.426c2.811-1.166,5.688-1.509,8.701-1.612c7.418-0.253,14.184,2.782,19.492,7.873
						c4.669,4.478,8.32,9.889,11.507,15.487c0.226,0.397,0.43,0.83,0.724,1.184c0.514,0.62,1.394-0.269,0.884-0.884
						c0.147,0.178-0.134-0.239-0.179-0.318c-0.19-0.335-0.381-0.67-0.572-1.004c-0.597-1.045-1.209-2.081-1.844-3.104
						c-1.877-3.021-3.943-5.932-6.251-8.64c-2.696-3.164-5.784-6.165-9.397-8.266c-3.392-1.973-7.226-3.123-11.126-3.487
						c-5.731-0.534-11.668,0.509-16.459,3.825c-6.064,4.196-9.698,11.109-10.48,18.366c-0.467,4.328-0.024,8.742,0.671,13.021
						c0.618,3.799,1.485,7.572,2.617,11.251c0.971,3.155,2.024,6.287,3.048,9.424c3.703,11.347,7.519,22.661,11.639,33.864
						c2.114,5.748,4.303,11.472,6.708,17.105c1.706,3.996,3.453,8.091,5.827,11.747c0.387,0.596,0.801,1.175,1.253,1.724
						C250.033,432.275,250.913,431.386,250.404,430.77L250.404,430.77z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    style={{ fill: quaternary }}
                                    d="M294.532,409.815c-3.137-9.763-6.702-19.406-10.155-29.061
						c-4.841-13.534-9.706-27.074-15.013-40.435c-1.006-2.534-2.029-5.063-3.128-7.559c-0.617-1.401-1.215-2.875-2.065-4.156
						c-0.443-0.667-1.526-0.042-1.079,0.631c1.955,2.944,3.128,6.586,4.437,9.84c2.451,6.092,4.763,12.241,7.05,18.396
						c4.988,13.426,9.815,26.914,14.544,40.434c1.424,4.071,2.883,8.135,4.203,12.242
						C293.572,410.91,294.779,410.584,294.532,409.815L294.532,409.815z"
                                />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                style={{ fill: quaternary }}
                                d="M332.37,282.279c1.835-18.253,3.669-36.506,5.504-54.76c0.523-5.203,1.046-10.407,1.569-15.61
					c0.08-0.8-1.17-0.794-1.25,0c-1.835,18.253-3.669,36.507-5.504,54.76c-0.523,5.203-1.046,10.407-1.569,15.61
					C331.039,283.078,332.29,283.073,332.37,282.279L332.37,282.279z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default SVGReceiveWhatsNew;
