import React, { useEffect, useRef } from "react";

import styles from "./index.module.scss";

interface Props {
    duration?: number;
    delay?: number;
    direction?: "normal" | "reverse" | "alternate" | "alternate-reverse" | "normal";
    pauseOnHover?: "running" | "paused" | "revert" | "normal";
    pauseOnClick?: "running" | "paused" | "revert" | "normal";
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

export const Marquee: React.FC<Props> = ({ duration = 20, delay = 0, direction = "normal", pauseOnHover = "normal", pauseOnClick = "normal", children, className, style }) => {
    return (
        <div
            className={[styles["Marquee-root"], className].join(" ")}
            style={
                {
                    ...style,
                    "--duration": typeof duration === "number" ? duration + "s" : "20s",
                    "--delay": typeof delay === "number" ? delay + "s" : "0s",
                    "--direction": ["normal", "reverse", "alternate", "alternate-reverse", "normal"].includes(direction) ? direction : "normal",
                    "--pauseOnHover": ["running", "paused", "revert", "normal"].includes(pauseOnHover) ? pauseOnHover : "normal",
                    "--pauseOnClick": ["running", "paused", "revert", "normal"].includes(pauseOnClick) ? pauseOnClick : "normal",
                    "--loops": "infinite",
                    "--gradient-color": "rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)",
                    "--gradient-width": "200px",
                    "--min-width": "100%",
                } as React.CSSProperties & { [key: string]: any }
            }
        >
            <div>{children}</div>
            <div>{children}</div>
        </div>
    );
};
