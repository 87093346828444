import { mdiDotsHorizontal } from "@mdi/js";
import Icon from "@mdi/react";
import { Breadcrumbs, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Link } from "Components/Link";
import React from "react";

interface Props {
    items: Array<{
        href: string;
        title: string;
    }>;
    maxItems?: number;
}

export const CondensedWithMenu: React.FC<Props> = ({ items, maxItems = 3 }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
        if (event) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const startItems = items.slice(0, 1);
    const middleItems = items.slice(1, items.length - maxItems + 1);
    const endItems = items.slice(items.length - maxItems + 1, items.length);

    return (
        <React.Fragment>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} aria-labelledby="with-menu-demo-breadcrumbs">
                {middleItems.map(({ href, title }, index) => {
                    return (
                        <MenuItem onClick={handleClose} key={"middle-item-" + index}>
                            {title}
                        </MenuItem>
                    );
                })}
            </Menu>
            <Breadcrumbs aria-label="breadcrumbs">
                {items.length > maxItems - 1 &&
                    startItems.map(({ href, title }, index) => {
                        return (
                            <Link underline="hover" color="inherit" href={href} key={"start-item-" + index}>
                                {title}
                            </Link>
                        );
                    })}

                {middleItems.length > 0 && (
                    <IconButton color="inherit" size="small" onClick={handleClick} disableRipple>
                        <Icon path={mdiDotsHorizontal} size={1} />
                    </IconButton>
                )}

                {endItems.map(({ href, title }, index, array) => {
                    const isLast = index === array.length - 1;

                    return isLast ? (
                        <Typography sx={{ color: "text.primary" }} key={"end-item-" + index}>
                            {title}
                        </Typography>
                    ) : (
                        <Link underline="hover" color="inherit" href={href} key={"end-item-" + index}>
                            {title}
                        </Link>
                    );
                })}
            </Breadcrumbs>
        </React.Fragment>
    );
};
