import React, { useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Marquee } from "../Marquee";

interface AssetData {
    id: string;
    rank: string;
    symbol: string;
    name: string;
    supply: string;
    maxSupply: string;
    marketCapUsd: string;
    volumeUsd24Hr: string;
    priceUsd: string;
    changePercent24Hr: string;
    vwap24Hr: string;
    explorer: string;
    icon?: string;
}

interface CoincapAssetsData {
    data: Array<AssetData>;
    timestamp: number;
}

const coincapAssetsData: CoincapAssetsData & {
    isPending: boolean;
} = {
    data: [],
    timestamp: 0,
    isPending: false,
};

const fiterDefault = [
    "bitcoin",
    "ethereum",
    "tether",
    "usd-coin",
    "binance-coin",
    "cardano",
    "binance-usd",
    "xrp",
    "solana",
    "dogecoin",
    "polkadot",
    "shiba-inu",
    "avalanche",
    "polygon",
    "multi-collateral-dai",
    "terra-luna",
    "uniswap",
    "tron",
    "wrapped-bitcoin",
    "ethereum-classic",
];

export const getCurrencyIndicatorsData = async () => {
    if (Date.now() - coincapAssetsData.timestamp < 1000 * 60 || coincapAssetsData.isPending) {
        return coincapAssetsData.data;
    }

    coincapAssetsData.isPending = true;

    const data = await fetch("https://api.coincap.io/v2/assets")
        .then((t) => t.json())
        .then(({ data }: CoincapAssetsData) => {
            //console.log(JSON.stringify(data.map(({id, name, symbol})=> ({value: id, label: `${symbol} - ${name}`}))));

            data = data
                .filter(({ id }) => typeof id === "string" && id)
                .map((d) => {
                    d.icon = `https://assets.coincap.io/assets/icons/${String(d.symbol).toLocaleLowerCase()}@2x.png`;
                    d.icon = `https://api.ivipcoin.com/v1/binance/assets/${String(d.symbol).toUpperCase()}`;
                    return d;
                });

            return Promise.resolve(data);
        })
        .catch(() => Promise.resolve(coincapAssetsData.data));

    coincapAssetsData.data = data;
    coincapAssetsData.timestamp = Date.now();
    coincapAssetsData.isPending = false;

    return coincapAssetsData.data;
};

export const useCurrencyIndicatorsData = (timeInterval: number = 60 * 1000, fiter: string[] = fiterDefault) => {
    const [data, setData] = useState<AssetData[]>(coincapAssetsData.data);

    const execute = useCallback(() => {
        getCurrencyIndicatorsData().then((data) =>
            setData(() => {
                return data.filter(({ id }) => fiter.includes(id));
            })
        );
    }, []);

    useEffect(() => {
        const time = window.setInterval(() => {
            execute();
        }, timeInterval);

        execute();

        return () => {
            window.clearInterval(time);
        };
    }, [timeInterval]);

    return data;
};

interface Props {
    style?: React.CSSProperties;
    className?: string;
}

export const CurrencyIndicatorsBar: React.FC<Props> = ({ className, style }) => {
    const listComponent = useCurrencyIndicatorsData(10 * 60 * 1000);

    return (
        <div className={[styles["main"], className].join(" ")} style={style}>
            <Marquee duration={120}>
                {listComponent.map(({ icon, name, symbol, priceUsd, changePercent24Hr }, i) => {
                    const indicator = parseFloat(changePercent24Hr) < 0 ? "fall" : "";
                    const price = parseFloat(parseFloat(priceUsd).toFixed(6));

                    return (
                        <div className={styles["rank"]} key={"CryptoRank-item-" + i}>
                            <div className={styles["icon"]} style={{ backgroundImage: `url("${icon}")` }}></div>
                            <div className={styles["content"]}>
                                <div className={styles["price"]}>
                                    {price.toLocaleString(undefined, {
                                        style: "currency",
                                        currency: "USD",
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 8,
                                    })}
                                </div>
                                <div className={styles["name"]}>
                                    {name}/{symbol}
                                </div>
                            </div>
                            <div className={[styles["indicator"], styles[indicator]].join(" ")}>
                                <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M11.93 5L14.76 7.83L5 17.59L6.42 19L16.18 9.25L19 12.07V5H11.93Z" />
                                </svg>
                                <span>{parseFloat(changePercent24Hr).toFixed(2)}%</span>
                            </div>
                        </div>
                    );
                })}
            </Marquee>
        </div>
    );
};
