import React from "react";
import { RouterProvider } from "react-router-dom";
import { Content, Header } from "Components";

import navigate from "./routes";
import { Routes } from "Utils";

export { navigate };

const Pages: React.FC = () => {
    const { itemSelected } = Routes.useRoutes(navigate.forMenu() ?? []);

    return (
        <>
            <Header menu={navigate.forMenu()} />
            <Content
                style={
                    {
                        paddingTop: "44px",
                        paddingLeft: "44px",
                        paddingBottom: "44px",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        "--primary": itemSelected?.color,
                    } as React.CSSProperties | { [key: string]: string | undefined }
                }
                scroll={{
                    x: {
                        position: {
                            left: 44,
                            bottom: 44,
                        },
                    },
                    y: {
                        position: {
                            top: 44,
                            bottom: 44,
                        },
                    },
                }}
                sectionId={itemSelected?.sectionId}
                onSectionView={(id) => {
                    if (itemSelected?.sectionId !== id) {
                        const path = navigate.router.routes.find(({ sectionId }: any) => sectionId === id)?.path ?? "/";
                        navigate.router.navigate(path, {
                            replace: true,
                        });
                    }
                }}
            >
                <RouterProvider router={navigate.router} future={{ v7_startTransition: true }} />
            </Content>
        </>
    );
};

export default Pages;
