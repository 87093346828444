import { Typography } from "@mui/material";
import React from "react";

const Page404: React.FC = () => {
    return (
        <div
            style={{
                width: "100%",
                minHeight: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                userSelect: "none",
                pointerEvents: "none",
            }}
        >
            <Typography
                variant="h1"
                style={{
                    fontWeight: "bold",
                    fontStyle: "italic",
                    opacity: 0.5,
                }}
            >
                404
            </Typography>
            <Typography
                variant="h4"
                style={{
                    opacity: 0.7,
                }}
            >
                Page Not Found
            </Typography>
        </div>
    );
};

export default Page404;
