import React, { useEffect, useLayoutEffect, useRef, createContext, useContext, useCallback } from "react";
import { OverlayScrollbarsComponent, OverlayScrollbarsComponentRef } from "overlayscrollbars-react";
import "overlayscrollbars/styles/overlayscrollbars.css";

import styles from "./style.module.scss";
import { Hooks } from "Utils";
import { OverlayScrollbars } from "overlayscrollbars";

interface ScrollProps {
    top: number;
    left: number;
    right: number;
    bottom: number;
    width: number;
    height: number;
    clientWidth: number;
    clientHeight: number;
}

const ScrollContext = createContext<{
    scroll: () => {
        top: number;
        left: number;
        right: number;
        bottom: number;
        width: number;
        height: number;
        clientWidth: number;
        clientHeight: number;
        to: (options?: ScrollToOptions) => void;
    };
    onScroll: (callback: (event: ScrollProps) => void) => void;
    offScroll: (callback: (event: ScrollProps) => void) => void;
}>({
    scroll: () => ({ top: 0, left: 0, right: 0, bottom: 0, width: 0, height: 0, clientWidth: 0, clientHeight: 0, to: () => {} }),
    onScroll: () => {},
    offScroll: () => {},
});

export const useContentScroll = () => {
    return useContext(ScrollContext);
};

interface ScrollOpt {
    enable: boolean;
    position: Partial<{
        top: number;
        left: number;
        right: number;
        bottom: number;
    }>;
}

const isElementVisible = (element: HTMLElement, holder?: HTMLElement, percentX: number = 20, percentY: number = 20) => {
    holder = holder || element.parentElement || document.body;
    const tolerance = 0.01;

    const elementRect = element.getBoundingClientRect();
    const parentRect = holder.getBoundingClientRect();

    const visiblePixelX = Math.min(elementRect.right, parentRect.right) - Math.max(elementRect.left, parentRect.left);
    const visiblePixelY = Math.min(elementRect.bottom, parentRect.bottom) - Math.max(elementRect.top, parentRect.top);
    const visiblePercentageX = (visiblePixelX / elementRect.width) * 100;
    const visiblePercentageY = (visiblePixelY / elementRect.height) * 100;

    return visiblePercentageX + tolerance > percentX && visiblePercentageY + tolerance > percentY;
};

interface Props {
    style?: React.CSSProperties;
    children?: React.ReactNode;
    scroll?: Partial<{
        x: Partial<ScrollOpt>;
        y: Partial<ScrollOpt>;
    }>;
    sectionId?: string;
    sectionIds?: Array<string>;
    onSectionView?: (sectionId: string) => void;
}

export const Content: React.FC<Props> = ({ style = {}, children, scroll, sectionId, sectionIds, onSectionView }) => {
    const { ref: rootElementRef, verifySectionId } = Hooks.useSectionViewport();
    const rootRef = useRef<OverlayScrollbarsComponentRef<"div"> | null>(null);
    const scrollEventsRef = useRef<Array<(event: ScrollProps) => void>>([]);

    const getSection = () => {
        if (!rootRef.current) {
            return;
        }

        const instance = rootRef.current.osInstance();

        const root = instance?.elements()?.content;

        if (!root) {
            return;
        }

        const { childNodes } = root;

        const nodes = Array.from(childNodes as NodeListOf<HTMLElement>).filter(({ id }) => !!id);

        const sections = sectionIds ?? nodes.map((n) => n.id);

        const visibleElementId = sections.find((id) => {
            return verifySectionId(id);
        });

        return visibleElementId && sections.includes(visibleElementId) ? visibleElementId : undefined;
    };

    const update = Hooks.useDebouncedCallback((instance: OverlayScrollbars) => {
        const { scrollOffsetElement, content } = instance.elements();
        rootElementRef(content);

        const currentSection = getSection();

        if (sectionId && currentSection !== sectionId) {
            const element = document.getElementById(sectionId);
            if (element) {
                const { offsetTop, offsetLeft } = element;
                let paddingTop = 0,
                    paddingLeft = 0;

                try {
                    const contentStyle = window.getComputedStyle(content);
                    paddingTop = parseInt(contentStyle.paddingTop, 0);
                    paddingLeft = parseInt(contentStyle.paddingLeft, 0);
                } catch {}

                scrollOffsetElement.scrollTo({
                    behavior: "smooth",
                    left: offsetLeft - paddingLeft,
                    top: offsetTop - paddingTop,
                });
            }
        }
    }, 100);

    useLayoutEffect(() => {
        if (!rootRef.current) {
            return;
        }

        const instance = rootRef.current.osInstance();

        if (instance) {
            update(instance);
        }
    }, [rootRef.current, sectionId]);

    const onScroll = Hooks.useDebouncedCallback((e: any) => {
        const sectionId = getSection();

        if (typeof onSectionView === "function" && sectionId) {
            onSectionView(sectionId);
        }
    }, 100);

    return (
        <OverlayScrollbarsComponent
            ref={rootRef}
            className={styles.main}
            defer
            style={
                {
                    ...style,
                    "--pos-x-top": `${scroll?.x?.position?.top ?? 0}px`,
                    "--pos-x-left": `${scroll?.x?.position?.left ?? 0}px`,
                    "--pos-x-right": `${scroll?.x?.position?.right ?? 0}px`,
                    "--pos-x-bottom": `${scroll?.x?.position?.bottom ?? 0}px`,
                    "--pos-y-top": `${scroll?.y?.position?.top ?? 0}px`,
                    "--pos-y-left": `${scroll?.y?.position?.left ?? 0}px`,
                    "--pos-y-right": `${scroll?.y?.position?.right ?? 0}px`,
                    "--pos-y-bottom": `${scroll?.y?.position?.bottom ?? 0}px`,
                } as React.CSSProperties & { [k: string]: string }
            }
            options={{
                showNativeOverlaidScrollbars: false,
                overflow: {
                    x: typeof scroll?.x?.enable !== "boolean" || scroll?.x?.enable ? "scroll" : "hidden",
                    y: typeof scroll?.y?.enable !== "boolean" || scroll?.y?.enable ? "scroll" : "hidden",
                },
                update: {
                    debounce: [0, 33],
                },
            }}
            events={{
                initialized(e) {
                    update(e);
                },
                updated(e) {
                    update(e);
                },
                scroll(e) {
                    onScroll(e);
                    const { content } = e.elements();

                    scrollEventsRef.current.forEach((callback) => {
                        callback({
                            top: content.scrollTop,
                            left: content.scrollLeft,
                            right: content.scrollLeft + content.clientWidth,
                            bottom: content.scrollTop + content.clientHeight,
                            width: content.scrollWidth,
                            height: content.scrollHeight,
                            clientWidth: content.clientWidth,
                            clientHeight: content.clientHeight,
                        });
                    });
                },
            }}
        >
            <ScrollContext.Provider
                value={{
                    scroll() {
                        const instance = rootRef.current?.osInstance();
                        const { scrollOffsetElement } = instance?.elements() ?? {};
                        return {
                            top: scrollOffsetElement?.scrollTop ?? 0,
                            left: scrollOffsetElement?.scrollLeft ?? 0,
                            right: (scrollOffsetElement?.scrollLeft ?? 0) + (scrollOffsetElement?.clientWidth ?? 0),
                            bottom: (scrollOffsetElement?.scrollTop ?? 0) + (scrollOffsetElement?.clientHeight ?? 0),
                            width: scrollOffsetElement?.scrollWidth ?? 0,
                            height: scrollOffsetElement?.scrollHeight ?? 0,
                            clientWidth: scrollOffsetElement?.clientWidth ?? 0,
                            clientHeight: scrollOffsetElement?.clientHeight ?? 0,
                            to(options?: ScrollToOptions) {
                                scrollOffsetElement?.scrollTo(options);
                            },
                        };
                    },
                    onScroll(callback) {
                        scrollEventsRef.current.push(callback);
                    },
                    offScroll(callback) {
                        scrollEventsRef.current = scrollEventsRef.current.filter((c) => c !== callback);
                    },
                }}
            >
                {children}
            </ScrollContext.Provider>
        </OverlayScrollbarsComponent>
    );
};
