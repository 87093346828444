import React, { Component } from "react";

export class ErrorBoundary extends Component {
    state = { hasError: false };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        console.log("ErrorBoundary caught an error", error, info);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong with the Scrollbar.</h1>;
        }

        return (this.props as any).children;
    }
}
