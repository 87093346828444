import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const useCallbackRef = <T extends (...args: any[]) => any>(callback: T | undefined): T => {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    });

    return useMemo(() => ((...args) => callbackRef.current?.(...args)) as T, []);
};

export const useDebouncedCallback = <T extends (...args: any[]) => any>(callback: T, delay: number) => {
    const handleCallback = useCallbackRef(callback);
    const debounceTimerRef = useRef(0);
    useEffect(() => () => window.clearTimeout(debounceTimerRef.current), []);

    return useCallback(
        (...args: Parameters<T>) => {
            window.clearTimeout(debounceTimerRef.current);
            debounceTimerRef.current = window.setTimeout(() => handleCallback(...args), delay);
        },
        [handleCallback, delay]
    );
};

class IntersectionObserver<T extends HTMLElement = any> {
    private onScroll: EventListener = () => {};
    private time: NodeJS.Timeout | undefined;

    constructor(
        observe: T[],
        onIntersection: (target: T) => void,
        readonly options: Partial<{
            threshold: number;
            root: HTMLElement;
        }> = {}
    ) {
        this.onScroll = () => {
            if (observe.length <= 0) {
                return;
            }

            window.clearTimeout(this.time);

            this.time = setTimeout(() => {
                const inViewport = observe.find((target, i, list) => {
                    const inViewport = this.isIntersecting(target);
                    const next = list[i + 1];
                    const nextInViewport = next && this.isIntersecting(next);
                    return inViewport && !nextInViewport;
                });

                if (!inViewport) {
                    return;
                }

                onIntersection(inViewport);
            }, 0);
        };

        this.root.addEventListener("scroll", this.onScroll);
        (this.onScroll as any)();
    }

    get root(): HTMLElement {
        return this.options?.root ?? document.body;
    }

    get threshold(): number {
        return this.options?.threshold ?? 1;
    }

    disconnect() {
        window.clearTimeout(this.time);
        this.root.removeEventListener("scroll", this.onScroll);
    }

    isIntersecting(target: T) {
        const rootRect = this.root.getBoundingClientRect();
        const rect = target.getBoundingClientRect();

        const viewportTop = (rootRect.bottom - rect.top) / rootRect.height >= this.threshold;
        const viewportBottom = (rect.bottom - rootRect.top) / rootRect.height >= this.threshold;
        const viewportLeft = (rootRect.right - rect.left) / rootRect.width >= this.threshold;
        const viewportRight = (rect.right - rootRect.left) / rootRect.width >= this.threshold;

        return viewportTop && viewportBottom && viewportLeft && viewportRight;
    }
}

export const useSectionViewport = <T extends HTMLElement = any>(threshold: number = 0.5) => {
    const [sectionId, setSectionId] = useState<string | null>(null);
    const sectionIdRef = useRef<string | null>(null);
    const observer = useRef<IntersectionObserver | null>(null);
    const mutation = useRef<MutationObserver | null>(null);

    const ref = useCallback(
        (element: T | null) => {
            if (observer.current) {
                observer.current.disconnect();
                observer.current = null;
            }

            if (mutation.current) {
                mutation.current.disconnect();
                mutation.current = null;
            }

            if (!element || element === null || !element.childNodes) {
                setSectionId(null);
                sectionIdRef.current = null;
                return;
            }

            const { childNodes } = element;

            const nodes = Array.from(childNodes as NodeListOf<HTMLElement>).filter(({ id }) => !!id);

            if (nodes.length <= 0) {
                return;
            }

            observer.current = new IntersectionObserver(
                nodes,
                (target) => {
                    setSectionId(target.id);
                    sectionIdRef.current = target.id;
                },
                { threshold, root: element }
            );

            const mutationObserver = new MutationObserver(() => {
                ref(element);
            });

            mutationObserver.observe(element, { childList: true, subtree: true });
            mutation.current = mutationObserver;
        },
        [threshold]
    );

    const verifySectionId = useCallback((id: string) => {
        return id === sectionIdRef.current;
    }, []);

    return { ref, sectionId, verifySectionId };
};
