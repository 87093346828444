import React from "react";
import ReactDOM from "react-dom/client";
import "./Styles/index.scss";
import Pages from "./Pages";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material";
import { ToastProvider } from "./Helpers";

const theme = createTheme({
    cssVariables: true,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <ToastProvider>
                <Pages />
            </ToastProvider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
